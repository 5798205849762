import React from 'react';
import styled from '@emotion/styled';
import colors from '../styles/colors';

const FooterSectionHeader = ({ title }) => {
  return <SectionHeader>{title}</SectionHeader>;
};

const SectionHeader = styled.h4`
  color: ${colors.darkNavy};
  font-size: 19px;
  line-height: 24.2px;
  margin-bottom: 29px;
`;

export default FooterSectionHeader;
