import algoliasearch from 'algoliasearch';

// algolia settings
const appId = process.env.REACT_APP_ALGOLIA_APP_ID;
const apiKey = process.env.REACT_APP_ALGOLIA_ADMIN_API_KEY;

export const SEARCH_CLIENT = algoliasearch(appId, apiKey);

// Algolia variables
export const INDEX_NAME = 'covidtimeline_stories_and_posts';

// For: SortBy(replica indices names from algolia)
export const CREATEDAT_ASC = 'covidtimeline_stories_and_posts_created_at_asc';
export const CREATEDAT_DESC = 'covidtimeline_stories_and_posts_created_at_desc';
