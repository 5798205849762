import styled from '@emotion/styled';
import React, { useEffect, useState, useContext } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { GlobalContext } from 'contexts/global/GlobalContext';
import UploadModal from '..//share_your_story_modal_components/UploadModal';
import NavbarHamburger from './NavbarHamburger';
import breakPoints from 'assets/styles/breakpoints';
import Logo from 'assets/images/pandemic-stories-logo.png';
import { HashLink as Link } from 'react-router-hash-link';

const NavBar = () => {
  const location = useLocation();
  const [{ isMobile }] = useContext(GlobalContext);
  const [navBarShadow, setNavBarShadow] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [isMobileNavOpen, setIsMobileNavOpen] = useState(false);

  const navigationLinks = [
    { path: '/stories', title: 'Your Stories' },
    { path: '/resources', title: 'Resources' },
    { path: '/ourmission', title: 'Our Mission' },
    { path: '/contactus', title: 'Contact Us' },
  ];

  const handleChange = (modalValue) => {
    setOpenModal(!modalValue);
  };

  //check if our current path is the same as the nav route we're on
  const isActive = (path) => {
    return path === location.pathname;
  };

  // changes navbar box shadow on scroll
  const changeNavBarDropShadow = () => {
    if (window.scrollY > 0) {
      setNavBarShadow(true);
    } else {
      setNavBarShadow(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', changeNavBarDropShadow);
    return () => {
      window.removeEventListener('scroll', changeNavBarDropShadow);
    };
  }, []);

  const renderMenuItems = () => {
    if (!isMobile) {
      return (
        <>
          <NavBarItems>
            <CustomNavLink to="/#timeline-start">
              <CustomLink
                active={
                  `${location.pathname}${location.hash}` == '/#timeline-start'
                    ? 1
                    : 0
                }
                to="/#timeline-start"
              >
                Timeline
              </CustomLink>
            </CustomNavLink>
            {navigationLinks.map((item) => (
              <CustomNavLink
                key={item.title}
                to={item.path}
                active={isActive(`${item.path}`) ? 1 : 0}
              >
                {item.title}
              </CustomNavLink>
            ))}
          </NavBarItems>
          <CustomButton onClick={() => setOpenModal(true)}>
            Share Your Story
          </CustomButton>
        </>
      );
    }

    return (
      <>
        <NavBarMobileItems>
          <CustomButton onClick={() => setOpenModal(true)}>
            Share Your Story
          </CustomButton>
          {!openModal && (
            <MobileIcons
              onClick={() => setIsMobileNavOpen(!isMobileNavOpen)}
              isMobileNavOpen={isMobileNavOpen}
            >
              <NavbarHamburger
                className="menuIcon"
                isMobileNavOpen={isMobileNavOpen}
              ></NavbarHamburger>
            </MobileIcons>
          )}
        </NavBarMobileItems>
        <NavBarMobileList isMobileNavOpen={isMobileNavOpen}>
          {navigationLinks.map((item) => (
            <NavBarMobileLink
              key={item.title}
              to={item.path}
              active={isActive(`${item.path}`) ? 1 : 0}
            >
              {item.title}
            </NavBarMobileLink>
          ))}
        </NavBarMobileList>
      </>
    );
  };

  return (
    <HeaderContainer shadow={navBarShadow}>
      <NavLink to="/">
        <img
          alt=""
          width="auto"
          height={isMobile ? '84.5px' : '100px'}
          padding="0"
          src={Logo}
        ></img>
      </NavLink>
      <Header>
        <CustomNavLink to="/" id="nav-title"></CustomNavLink>
        {renderMenuItems()}

        <UploadModal openModal={openModal} onChange={handleChange} />
      </Header>
    </HeaderContainer>
  );
};

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0px 100px;
  position: sticky;
  background-color: white;
  z-index: 99;
  top: 0;

  @media only screen and (max-width: 767px) {
    padding: 0px 0px;
  }

  box-shadow: ${(props) =>
    props.shadow ? '0px 0px 10px rgba(0, 0, 0, 0.3)' : 'none'};
`;

const Header = styled.div`
  width: 100%;
  padding: 27.5px 0px;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  align-items: center;

  #nav-title {
    font-family: Khula-SemiBold;
    font-size: 24px;
    font-weight: 600;
    line-height: 39px;
    color: #121212;

    @media only screen and (max-width: ${breakPoints.tablet}) {
      font-size: 17px;
      line-height: 19px;
    }
  }

  #main {
    font-family: Khula-ExtraBold;
    font-weight: 800;
    font-size: 24px;
    color: #00c2ba;
    @media only screen and (max-width: ${breakPoints.tablet}) {
      font-size: 17px;
    }
  }

  @media only screen and (max-width: 1024px) {
    width: 100%;
    box-sizing: border-box;
  }

  @media only screen and (max-width: ${breakPoints.tablet}) {
    justify-content: space-between;
    padding: 20px;
  }
`;

const NavBarItems = styled.div`
  font-family: 'Mulish-Regular';
  width: 50%;
  display: flex;
  justify-content: space-evenly;
  text-align: center;
  align-items: center;
`;

const CustomNavLink = styled(NavLink)`
  text-decoration: none;
  cursor: pointer;
  font-size: 14px;
  line-height: 24px;
  vertical-align: middle;
  color: ${(props) => (props.active ? '#00c2ba' : '#121212')};

  :hover {
    color: ${(props) => (props.active ? 'none' : '#00c2ba')};
  }
`;

const CustomLink = styled(Link)`
  text-decoration: none;
  cursor: pointer;
  font-size: 14px;
  line-height: 24px;
  vertical-align: middle;
  color: ${(props) => (props.active ? '#00c2ba' : '#121212')};

  :hover {
    color: ${(props) => (props.active ? 'none' : '#00c2ba')};
  }
`;

const CustomButton = styled.button`
  font-family: 'Khula-Bold';
  font-size: 16px;
  border-radius: 30px;
  background: #00c2ba;
  color: white;
  width: 201px;
  height: 45px;
  border: none;
  cursor: pointer;
  text-align: center;
  padding-top: 5px;

  :hover {
    opacity: 0.8;
  }

  @media only screen and (max-width: ${breakPoints.tablet}) {
    font-family: 'Khula-SemiBold';
    font-size: 12.5px;
    line-height: 17px;
    width: 120px;
    height: 33px;
    padding: 10px;
    margin-right: 5px;
  }
`;

const MobileIcons = styled.div`
  background: #00c2ba;
  border-radius: 50%;
  height: 33px;
  width: 33px;
  z-index: 2;
  margin-right: 5.75px;
  .menuIcon {
    display: ${(props) => (props.isMobileNavOpen ? 'none' : 'block')};
    font-size: 34px;
  }
`;

const NavBarMobileItems = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const NavBarMobileList = styled.ul`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  transition: 'transform 300ms cubic-bezier(0.785, 0.135, 0.15, 0.86)';
  width: 60vw;
  height: 100vh;
  position: fixed;
  top: 0;
  right: 0;
  margin: 0;
  padding: 70px 0px 30px;
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05),
    0px 30px 60px 5px rgba(49, 49, 49, 0.15);
  box-sizing: border-box;
  overflow-y: auto;
  background: white;
  transform: ${(props) =>
    props.isMobileNavOpen ? 'translateX(0vw)' : 'translateX(100vw)'};
  z-index: 1;
  list-style: none;
`;

const NavBarMobileLink = styled(NavLink)`
  color: #0f374a;
  text-decoration: none;
  padding: 30px;
  font-family: ${(props) => (props.active ? 'Khula-Bold' : 'Khula-SemiBold')};
  background: ${(props) => (props.active ? '#e2f5fe' : 'white')};
`;

export default NavBar;
