import React from 'react';
import { Link } from 'react-router-dom';
import styled from '@emotion/styled';
import stepThreePic from '../../assets/images/step3.svg';
import breakPoints from 'assets/styles/breakpoints';

const StepThree = ({ backToStepOne, closeModal }) => {
  return (
    <StepWrapper>
      <h5 id="stepNumber">Step 3</h5>
      <h4 id="stepTitle">Add Your Stories</h4>
      <p id="subtitle">Please share your story of the pandemic with us!</p>
      <img src={stepThreePic} alt="" />
      <ButtonsWrapper>
        <UploadMore onClick={backToStepOne}>I Want To Upload More</UploadMore>
        <SeePeoplesStories to="/stories" onClick={closeModal}>
          See People&apos;s Stories
        </SeePeoplesStories>
      </ButtonsWrapper>
    </StepWrapper>
  );
};

const StepWrapper = styled.div`
  display: flex;
  padding: 30px 70px 30px;
  flex-direction: column;
  justify-content: center;

  #stepNumber {
    color: #00c2ba;
    margin-top: 30px;
  }

  #stepTitle {
    margin-top: 10px;
  }

  #subtitle {
    font-family: 'Khula-Regular';
    font-size: 16px;
    line-height: 25px;
    color: #0f374a;
    margin-top: 15px;
  }

  img {
    width: 100%;
    object-fit: cover;
  }

  @media only screen and (max-width: ${breakPoints.tablet}) {
    padding: 30px 0px;

    #stepNumber {
      padding: 0 50px;
      margin: 0px;
    }

    #stepTitle {
      padding: 3px 50px 0px;
      margin: 0px;
    }

    #subtitle {
      padding: 7px 50px 0px;
      margin: 0px;
    }
  }
`;

const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
`;

const UploadMore = styled.button`
  border: none;
  background: none;
  font-family: Khula-Bold;
  font-size: 16px;
  line-height: 25px;
  cursor: pointer;
  color: #fab550;
  margin: 10px 0 12px;
  text-decoration: underline;
  text-underline-offset: 3px;
  text-align: center;
  white-space: nowrap;
`;

const SeePeoplesStories = styled(Link)`
  --width: 33%;
  min-width: 33%;
  height: 40px;
  color: #ffffff;
  background: #fab550;
  padding-top: 3px;
  padding-right: calc(var(--width) * 0.3);
  padding-left: calc(var(--width) * 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  font-family: Khula-Bold;
  font-size: 16px;
  line-height: 25px;
  text-align: center;
  white-space: nowrap;
  border-radius: 50px;
  cursor: pointer;
  border: none;
`;

export default StepThree;
