/*eslint-disable*/
import styled from '@emotion/styled';
import { PageContainer } from 'assets/styledComponents/PageContainer';
import HeaderWithDots from 'assets/styledComponents/TitleHeader2WithDots';
import colors from 'assets/styles/colors';
import ScrollToTopButton from 'components/ScrollToTopButton';
import DropDownPill from 'components/StoriesPage/DropDownPill';
import FilterByCategory from 'components/StoriesPage/FilterByCategory';
import SearchFilterPillBar from 'components/StoriesPage/SearchFilterPillBar';
import SortByOptions from 'components/StoriesPage/SortByOptions';
import StorySearchBar from 'components/StoriesPage/StorySearchBar';
import React, { useState, useContext } from 'react';
import { InstantSearch, Hits } from 'react-instantsearch-dom';
import StoriesSortType from 'types/StoriesSortType';
import {
  CREATEDAT_ASC,
  CREATEDAT_DESC,
  INDEX_NAME,
  SEARCH_CLIENT,
} from 'utils/algoliaConfigs';
import { GlobalContext } from 'contexts/global/GlobalContext';
import Header3 from 'assets/styledComponents/Header3';

import breakPoints from 'assets/styles/breakpoints';
import ContentCard from 'components/StoriesPage/ContentCard';
import StoryTypes from 'types/StoryTypes';
import CustomPagination from 'components/StoriesPage/CustomPagination';

import { useQuery } from '@apollo/client';
import { GET_STORIES_PAGE } from 'graphql/queries';

const StoriesPage = () => {
  const [{ isMobile }] = useContext(GlobalContext);
  const [sortOption, setSortOption] = useState(StoriesSortType.SORT_BY_NEWEST);
  const [searchTerm, setSearchTerm] = useState('');

  const {
    loading: yourStoriesPageLoading,
    data: yourStoriesPageData,
    error: yourStoriesPageError,
  } = useQuery(GET_STORIES_PAGE);

  const renderSortPlaceholder = () => {
    if (isMobile) return sortOption.replace('Sort by ', '');
    return sortOption;
  };

  if (!yourStoriesPageData) return <p>Loading...</p>;

  if (yourStoriesPageData)
    return (
      <PageContainer backgroundColor={colors.lightBluegreen}>
        <InstantSearch indexName={INDEX_NAME} searchClient={SEARCH_CLIENT}>
          <StyledHeaderDiv>
            {isMobile ? (
              <Header3 title="Your Stories" />
            ) : (
              <HeaderWithDots title="Your Stories" />
            )}
            <FiltersDiv>
              <StorySearchBar
                setSearchTerm={setSearchTerm}
                translations={{ placeholder: 'Search' }}
                currentRefinement={searchTerm}
                searchTerm={searchTerm}
              />
              <DropDownPill
                placeholder="Filter by"
                width={isMobile ? '170' : '160'}
              >
                <FilterByCategory />
              </DropDownPill>
              <DropDownPill
                placeholder={renderSortPlaceholder()}
                width={isMobile ? '70' : '150'}
              >
                <SortByOptions
                  setSortOption={setSortOption}
                  defaultRefinement={INDEX_NAME}
                  items={[
                    {
                      value: CREATEDAT_DESC,
                      label: StoriesSortType.SORT_BY_NEWEST,
                    },
                    {
                      value: CREATEDAT_ASC,
                      label: StoriesSortType.SORT_BY_OLDEST,
                    },
                  ]}
                />
              </DropDownPill>
            </FiltersDiv>
          </StyledHeaderDiv>
          <SearchFilterPillBar
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
          />
          <Description>
            {yourStoriesPageData.yourStoriesPage.Description}
          </Description>
          <HitsContainer>
            <Hits
              showPrevious={false}
              hitComponent={({ hit }) => {
                const story = hit;
                return (
                  <ContentCard
                    key={story.id}
                    className={'grid-item'}
                    date_stamp={story.date}
                    title={
                      story.type === StoryTypes.SOCIAL_MEDIA_POST
                        ? story.post.title
                        : story.title
                    }
                    description={
                      story.type === StoryTypes.SOCIAL_MEDIA_POST
                        ? story.post.description
                        : story.yourStory
                    }
                    name={
                      story.type === StoryTypes.SOCIAL_MEDIA_POST
                        ? story.post.author_name
                        : story.name
                    }
                    postalCode={
                      story.type === StoryTypes.SOCIAL_MEDIA_POST
                        ? ''
                        : story.postalCode
                    }
                    storyMediaContent={
                      story.type === StoryTypes.SOCIAL_MEDIA_POST
                        ? {
                            type: StoryTypes.SOCIAL_MEDIA_POST,
                            media: {
                              image: story.post.image_url,
                              video: story.post.video_url,
                            },
                          }
                        : {
                            type: StoryTypes.STORY,
                            media: story.storyMediaContent,
                          }
                    }
                    link={
                      story.type === StoryTypes.SOCIAL_MEDIA_POST &&
                      story.post.link
                    }
                    socialMediaSource={
                      story.type === StoryTypes.SOCIAL_MEDIA_POST &&
                      story.post.source.network
                    }
                  />
                );
              }}
            />
          </HitsContainer>
          <PaginationWrapper>
            <CustomPagination></CustomPagination>
          </PaginationWrapper>
          <ScrollToTopButton />
        </InstantSearch>
      </PageContainer>
    );
};

const FiltersDiv = styled.div`
  display: flex;
  align-items: center;

  @media only screen and (max-width: ${breakPoints.mobile}) {
    margin: 20px 0px;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    width: 100%;
    div {
      margin: 0px;
      font-size: 12.5px;
    }
    div:nth-of-type(2n) {
      margin: 0 35px 0 5px;
    }
  }
`;

const StyledHeaderDiv = styled.div`
  max-width: 1160px;
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media only screen and (max-width: ${breakPoints.mobile}) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
`;

const Description = styled.div`
  max-width: 1160px;
  margin: auto;
  padding-bottom: 90px;
  position: relative;
  font-family: Khula-Regular;
  font-size: 18px;
`;

const HitsContainer = styled.div`
  display: flex;
  justify-content: center;

  ul {
    list-style: none;
  }

  .ais-Hits-list {
    display: grid;
    grid-template-columns: repeat(3, max-content);
    gap: 40px;
  }

  @media only screen and (max-width: 1160px) {
    .ais-Hits-list {
      grid-template-columns: repeat(2, max-content);
    }
  }

  @media only screen and (max-width: 780px) {
    .ais-Hits-list {
      grid-template-columns: repeat(1, max-content);
    }
  }
`;

const PaginationWrapper = styled.div`
  display: flex;
  justify-content: center;

  .ais-Pagination-list {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 200px;
  }

  @media only screen and (max-width: 1160px) {
    padding-bottom: 5rem;
  }
`;

export default StoriesPage;
