import React from 'react';
import PrivacyPolicyText from './PrivacyPolicyText';
import Header from '../../components/landing_components/Header';
import colors from '../../assets/styles/colors';

const PrivacyPolicyPage = () => {
  return (
    <>
      <Header color={colors.almostBlack} title="Privacy Policy" />
      <PrivacyPolicyText />
    </>
  );
};

export default PrivacyPolicyPage;
