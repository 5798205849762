import styled from '@emotion/styled';
import colors from 'assets/styles/colors';
import React from 'react';
import SocialMediaSources from 'types/SocialMediaSources';
import facebook from '../../../assets/images/facebook_logo.svg';
import instagram from '../../../assets/images/instagram_logo.svg';
import redirect from '../../../assets/images/redirect_black.svg';
import twitter from '../../../assets/images/twitter_logo.svg';
import youtube from '../../../assets/images/youtube_logo.svg';

const SocialMediaLink = ({ link, socialMediaSource }) => {
  const getLogoSrc = (socialMediaSource) => {
    let src;
    switch (socialMediaSource) {
      case SocialMediaSources.INSTAGRAM:
        src = instagram;
        break;
      case SocialMediaSources.TWITTER:
        src = twitter;
        break;
      case SocialMediaSources.FACEBOOK:
        src = facebook;
        break;
      case SocialMediaSources.YOUTUBE:
        src = youtube;
        break;
      default:
        src = twitter;
    }
    return src;
  };

  return (
    <Container>
      <LinkContainer>
        <MediaIcon
          src={getLogoSrc(socialMediaSource)}
          alt={socialMediaSource}
        />
        <Link href={link} target="_blank" rel="noreferrer">
          See the{' '}
          {socialMediaSource === SocialMediaSources.YOUTUBE ? 'video' : 'post'}{' '}
          on {socialMediaSource}
          <LinkIcon src={redirect} alt="link" />
        </Link>
      </LinkContainer>
    </Container>
  );
};

const Container = styled.div`
  z-index: 4;
  position: absolute;
  width: 360px;
  height: 30px;
  display: flex;
  justify-content: flex-end;
`;

const LinkContainer = styled.div`
  width: 210px;
  text-align: center;
  background-color: ${colors.lightBlue};
  padding-bottom: 5px;
`;

const MediaIcon = styled.img`
  width: 15px;
  height: 15px;
  margin-bottom: -3px;
  margin-right: 3px;
`;

const Link = styled.a`
  text-decoration: none;
  font-family: Mulish-Regular;
  color: ${colors.darkNavy};
  font-size: 12.5px;
  line-height: 17px;
`;

const LinkIcon = styled.img`
  margin-left: 5px;
  margin-top: 5px;
`;

export default SocialMediaLink;
