import styled from '@emotion/styled';
import colors from 'assets/styles/colors';
import React from 'react';
import { connectSortBy } from 'react-instantsearch-dom';
import breakPoints from 'assets/styles/breakpoints';

const SortByOptions = ({ items, refine, setSortOption }) => {
  return (
    <SortOptionWrapper>
      {items.map((option) => (
        <ListItem key={option.value}>
          <Button
            onClick={(event) => {
              event.preventDefault();
              refine(option.value);
              setSortOption(option.label);
            }}
          >
            {option.label}
          </Button>
        </ListItem>
      ))}
    </SortOptionWrapper>
  );
};

const SortOptionWrapper = styled.ul`
  display: flex;
  flex-direction: column;
  position: absolute;
  background-color: white;
  width: inherit;
  z-index: 54;
  padding: 28px 15px 18px;
  transform: translate(-70px, -18px);
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.3);

  @media only screen and (max-width: ${breakPoints.mobile}) {
    width: 70px;
    right: -135px;
  }
`;

const ListItem = styled.li`
  list-style: none;
  cursor: pointer;
`;

const Button = styled.button`
  border: none;
  cursor: pointer;
  width: 100%;
  height: 100%;
  text-align: left;
  background-color: transparent;
  font-family: 'Mulish-Regular', sans-serif;
  font-weight: 600px;
  letter-spacing: 0.035rem;
  font-size: 0.78125rem;
  :hover {
    color: ${colors.bluegreen};
  }
  :first-of-type {
    margin-bottom: 8px;
  }
`;

export default connectSortBy(SortByOptions);
