import React from 'react';
import { connectCurrentRefinements } from 'react-instantsearch-dom';
import styled from '@emotion/styled';
import colors from 'assets/styles/colors';
import { Body5 } from 'assets/styledComponents/Body5';

const ClearRefinements = ({ items, refine }) => {
  return (
    <Container>
      <StyledButton onClick={() => refine(items)} items={items}>
        <Body5>Clear All filters</Body5>
      </StyledButton>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  margin-bottom: 15px;
`;

const StyledButton = styled.button`
  width: 150px;
  height: 25px;
  margin: 0 auto;
  background: ${colors.lightBlue};
  border-radius: 20px;
  border: none;
  :hover {
    cursor: pointer;
  }
  display: ${(props) => (props.items.length ? 'block' : 'none')};
`;

export default connectCurrentRefinements(ClearRefinements);
