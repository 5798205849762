import React from 'react';
import styled from '@emotion/styled';
import colors from '../../assets/styles/colors';

export default function VerticalScrub({ highlightedYear }) {
  return (
    <VerticalWrapper>
      <Thumb highlightedYear={highlightedYear} />
    </VerticalWrapper>
  );
}

const VerticalWrapper = styled.div`
  position: relative;
  width: 3px;
  margin-bottom: 7px;
  background-color: ${colors.lightBluegreen};
`;

const Thumb = styled.div`
  width: 7px;
  border-radius: 4px;
  height: 45px;
  background-color: ${colors.bluegreen};
  transform: translate(
    -2px,
    calc(63.5px * ${(props) => props.highlightedYear})
  );
`;
