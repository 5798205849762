import { useQuery } from '@apollo/client';
import styled from '@emotion/styled';
import LoadingSpinner from 'assets/styledComponents/LoadingSpinner';
import React from 'react';
import bcchLogo from '../assets/images/bcchLogo.svg';
import digitalLabLogo from '../assets/images/digital-lab-logo.svg';
import FooterSectionHeader from '../assets/styledComponents/FooterSectionHeader';
import colors from '../assets/styles/colors';
import { GET_CONTACT_DATA } from '../graphql/queries';

const Footer = () => {
  const {
    loading: footerLoading,
    data: footerData,
    error: footerError,
  } = useQuery(GET_CONTACT_DATA);

  if (footerLoading) {
    return <LoadingSpinner />;
  }
  if (footerError) {
    return <p>error</p>;
  }

  const navigationLinks = [
    { path: '/', title: 'My Pandemic Stories' },
    { path: '/stories', title: 'Your Story' },
    { path: '/ourmission', title: 'Our Mission' },
    { path: '/resources', title: 'Resources' },
    { path: '/contactus', title: 'Contact Us' },
  ];

  const supportLinks = [{ path: '/policy', title: 'Privacy Policy' }];

  const contactData = [
    { title: 'Email', data: footerData.footer.email },
    { title: 'Address', data: footerData.footer.address },
  ];

  return (
    <FooterContainer>
      <LogoSection>
        <LogoHeader>My Pandemic Stories</LogoHeader>
        <LogosContainer>
          <img src={bcchLogo} alt="bcch logo" />
          <DigitalLabLogo
            src={digitalLabLogo}
            alt="Digital Lab logo"
          ></DigitalLabLogo>
        </LogosContainer>
      </LogoSection>
      <NavigationContainer>
        <FooterSectionHeader title="Navigation" />
        <ul>
          {navigationLinks.map((item) => (
            <ListItem key={item.title}>
              <NavLink href={item.path} key={item.title}>
                {item.title}
              </NavLink>
            </ListItem>
          ))}
        </ul>
      </NavigationContainer>
      <SupportContainer>
        <FooterSectionHeader title="Support" />
        <ul>
          {supportLinks.map((item) => (
            <ListItem key={item.title}>
              <NavLink href={item.path} key={item.title}>
                {item.title}
              </NavLink>
            </ListItem>
          ))}
        </ul>
      </SupportContainer>
      <ContactUsContainer>
        <FooterSectionHeader title="Contact Us" />
        <ul>
          {contactData.map((item) => (
            <ListItem key={item.title}>
              <Subheader>{item.title}</Subheader> <Body3>{item.data}</Body3>
            </ListItem>
          ))}
        </ul>
      </ContactUsContainer>
    </FooterContainer>
  );
};

const FooterContainer = styled.div`
  display: grid;
  grid-template-columns: 40% repeat(3, 1fr);
  grid-template-areas: 'logoSection navSection supportSection contactSection';
  background-color: ${colors.lightYellow};
  padding: 100px 140px;
  column-gap: 2rem;
  @media only screen and (max-width: 778px) {
    grid-template-columns: 0.8fr 1.2fr;
    grid-template-areas:
      ' logoSection logoSection'
      ' supportSection contactSection';
    padding: 55px 28px 67px 28px;
  }

  ul {
    list-style: none;
  }
`;

const LogoSection = styled.div`
  grid-area: logoSection;
`;

const LogoHeader = styled.h3`
  color: ${colors.darkNavy};
`;

const LogosContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
  flex-wrap: wrap;
  margin-top: 33px;
  margin-bottom: 50px;
  gap: 30px;
  @media only screen and (max-width: 778px) {
    display: grid;
    grid-template-columns: 0.8fr 1.2fr;
    column-gap: 2rem;
  }
`;

const DigitalLabLogo = styled.img`
  padding-bottom: 10px;
`;

const NavigationContainer = styled.div`
  grid-area: navSection;
  @media only screen and (max-width: 778px) {
    display: none;
  }
`;
const SupportContainer = styled.div`
  grid-area: supportSection;
`;

const ContactUsContainer = styled.div`
  grid-area: contactSection;
`;

const NavLink = styled.a`
  font-family: 'Khula-Regular';
  font-size: 16px;
  line-height: 26px;
  color: ${colors.darkNavy};
  text-decoration: none;
  margin-top: 15px;
  :hover {
    opacity: 0.5;
  }
`;

const ListItem = styled.li`
  margin-bottom: 1rem;
`;

const Subheader = styled.p`
  font-family: 'Khula-Bold';
  font-weight: 700;
  font-size: 16px;
  color: ${colors.darkNavy};
  line-height: 26px;
`;

const Body3 = styled.p`
  font-family: 'Khula-Regular';
  font-size: 16px;
  color: ${colors.darkNavy};
  line-height: 26px;
`;
export default Footer;
