import { gql } from '@apollo/client';

export const GET_LANDING_PAGE = gql`
  query landingPage {
    landingPage {
      hero_section {
        title
        banner_image {
          url
        }
        banner_image_mobile {
          url
        }
      }
      testimonial_section {
        title
        description
      }
      share_your_story {
        title
        card {
          id
          description
          image {
            url
          }
        }
      }
      timeline_description
      milestone {
        title
        date_stamp
        description
        milestone_image {
          image {
            url
          }
          id
          alt_text
        }
      }
    }
  }
`;

export const GET_MISSION_PAGE = gql`
  query missionPage {
    missionPage {
      title
      goal_section {
        title
        description
      }
      objectives_section {
        title
        description
        objectives_items {
          id
          description
        }
      }
      acknowledgements_section {
        title
        description
      }
    }
  }
`;

export const GET_RESOURCES_PAGE = gql`
  query resourcesPage {
    resourcesPage {
      title
      description
    }
  }
`;

export const GET_STORIES_PAGE = gql`
  query yourStoriesPage {
    yourStoriesPage {
      Title
      Description
    }
  }
`;

export const GET_CONTACT_DATA = gql`
  query footer {
    footer {
      email
      address
    }
  }
`;

export const GET_RESOURCES = gql`
  query getResources {
    resources {
      id
      title
      description
      link
      tag
      resourceImage {
        id
        alternativeText
        image {
          id
          url
          formats
        }
      }
    }
  }
`;
