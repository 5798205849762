import styled from '@emotion/styled';
import Header from 'components/landing_components/Header';
import TimelineScubber from 'components/landing_components/TimelineScrubber';
import dateFormat from 'dateformat';
import { default as React, useEffect, useState, useRef } from 'react';
import { VerticalTimeline } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import TimelineElement from './TimelineElement';
import timelineArrow from 'assets/images/timeline-down-arrow.svg';
import breakPoints from '../../assets/styles/breakpoints';

const Timeline = ({ milestones, setYearArray, yearArray, description }) => {
  const [indexArray, setIndexArray] = useState([]);
  const [selectedYear, setSelectedYear] = useState(null);
  const timelineStart = useRef(null);

  let timelineCards = [];
  for (let key in milestones) {
    timelineCards.push(milestones[key]);
  }

  //smoothes out click jump on the scrubber
  useEffect(() => {
    selectedYear &&
      document.getElementById(selectedYear).scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
  }, [selectedYear]);

  //collects all unique years and gets the index of the first timeline card of that year from milestones so we can use them to jump with scrubber.
  useEffect(() => {
    if (milestones) {
      const years = [];
      const indices = [];
      for (let elem of milestones) {
        if (!years.includes(dateFormat(elem.date_stamp, 'yyyy'))) {
          years.push(dateFormat(elem.date_stamp, 'yyyy'));
        }
      }
      setYearArray(years);
      for (let year of years) {
        indices.push(
          milestones.findIndex(
            (elem) => dateFormat(elem.date_stamp, 'yyyy') === year
          )
        );
      }
      setIndexArray(indices);
    }
    //eslint-disable-next-line
  }, []);

  //used to add ids to first timeline cards found in previous useEffect
  const handleId = (ind) => {
    if (indexArray.includes(ind)) {
      return yearArray[indexArray.indexOf(ind)];
    }
  };

  return (
    <TimelineWrapper>
      <TimelineScubber
        yearArray={yearArray}
        selectedYear={selectedYear}
        setSelectedYear={setSelectedYear}
      />
      <div ref={timelineStart} id="timeline-start"></div>
      <Header color="white" title="COVID-19 Timeline" />
      <Description
        dangerouslySetInnerHTML={{ __html: description }}
      ></Description>
      <Line>
        {timelineCards.map((card, index) => (
          <TimelineElement card={card} id={handleId(index)} key={index} />
        ))}
      </Line>
      <LineArrow>
        <img src={timelineArrow} alt="timeline down arrow" />
      </LineArrow>
    </TimelineWrapper>
  );
};
const TimelineWrapper = styled.div`
  background-color: #0f374a;
  padding: 50px 0px 200px;

  #arrow {
    transform: scaleY(-1);
    position: absolute;
    left: 48.8%;
  }
  @media only screen and (max-width: 1170px) {
    #arrow {
      left: 0.5%;
    }
  }

  @media only screen and (max-width: ${breakPoints.tablet}) {
    padding: 0px 0px 60px;
    width: 100vw;
    overflow: hidden;
  }
`;

const Line = styled(VerticalTimeline)`
  &::before {
    width: 12px;
    background: #00c2ba;
    top: 25px;

    @media only screen and (max-width: ${breakPoints.tablet}) {
      left: 20px;
    }
  }

  div {
    scroll-margin-top: 120px;
  }

  position: relative;
  padding: 0;

  @media only screen and (max-width: ${breakPoints.tablet}) {
    padding: 20px 20px 20px 0;
  }
`;

const LineArrow = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  margin-left: 7.5px;

  @media only screen and (max-width: 1170px) {
    width: 95%;
    margin: 0 auto;
    padding: 0px;
    justify-content: flex-start;

    img {
      position: absolute;
      left: 7.5px;
    }
  }

  @media only screen and (max-width: ${breakPoints.tablet}) {
    img {
      left: 0.5px;
    }
  }
`;

const Description = styled.div`
  margin: 0 140px 90px;
  position: relative;
  font-family: Khula-Regular;
  font-size: 18px;
  color: white !important;

  @media only screen and (max-width: ${breakPoints.tablet}) {
    margin: 0 0 25px;
    padding: 55px 20px 0px;
  }
`;

export default Timeline;
