import styled from '@emotion/styled';
import breakPoints from 'assets/styles/breakpoints';
import React, { useState } from 'react';
import dropdownArrow from '../../../assets/images/icon_dropdownArrow.svg';
import colors from '../../../assets/styles/colors';

const SelectField = ({
  summary,
  options,
  activeOption,
  handleSelectOption,
  startState,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [input, setInput] = useState(startState);

  const handleKeyDown = (e) => {
    if (e.keyCode == 13) {
      e.preventDefault();
      handleSelect(e.target.value);
    }
  };

  const handleSelect = (option) => {
    //e.preventDefault();
    setInput(option);
    handleSelectOption(option);
  };

  return (
    <SelectContainer>
      <SelectBox>
        <Input
          showModal={showModal}
          onFocus={() => {
            setShowModal(true);
          }}
          placeholder={activeOption ? '' : summary}
          value={input}
          onChange={(e) => setInput(e.target.value)}
          onKeyDown={(e) => handleKeyDown(e)}
        />
        <Button
          onClick={(e) => {
            e.preventDefault();
            setShowModal((prev) => !prev);
          }}
        >
          <img src={dropdownArrow} alt="dropdown arrow" />
        </Button>
      </SelectBox>
      {showModal && (
        <OptionsContainer showModal={showModal}>
          {options
            .filter((option) =>
              option.toUpperCase().startsWith(input.toUpperCase())
            )
            .map((option) => (
              <Option
                key={option}
                onClick={(e) => {
                  e.preventDefault();
                  setShowModal(false);
                  handleSelectOption(option);
                  setInput(option);
                }}
                isActive={option === activeOption}
              >
                {option}
              </Option>
            ))}
        </OptionsContainer>
      )}
    </SelectContainer>
  );
};

const SelectContainer = styled.div`
  margin-top: 10px;
  position: relative;
  cursor: pointer;
`;

const SelectBox = styled.div`
  width: 100%;
  height: 35px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  position: relative;
  margin-top: 10px;
`;

const Input = styled.input`
  font-family: Khula-Regular;
  font-size: 16px;
  border-radius: ${(props) => (props.showModal ? '5px 5px 0px 0px' : '5px')};
  border: 1px solid #a0a8ac;
  height: 35px;
  width: 100%;
  padding: 5px 0px 0px 15px;
`;

const Button = styled.button`
  border: none;
  background-color: transparent;
  position: absolute;
  right: 0;
  top: 0px;
  padding: 5px 10px;
  cursor: pointer;
`;

const OptionsContainer = styled.div`
  font-family: Khula-Regular;
  display: ${(props) => (props.showModal ? 'block' : 'none')};
  overflow-y: auto;
  position: absolute;
  background-color: ${colors.white};
  border: 1px solid ${colors.grey};
  top: 100%;
  width: 500px;
  z-index: 50;
  max-height: 330px;
  min-height: 35px;
  overflow: scroll;

  @media only screen and (max-width: ${breakPoints.tablet}) {
    width: 100%;
  }
`;

const Option = styled.button`
  width: 100%;
  background-color: ${colors.white};
  color: ${(props) => (props.isActive ? colors.bluegreen : colors.almostBlack)};
  border: none;
  font: inherit;
  padding: 5px 0px 0px 15px;
  text-align: left;
  box-sizing: border-box;
  display: block;
  :hover {
    cursor: pointer;
    opacity: 0.35;
  }
`;

export default SelectField;
