import styled from '@emotion/styled';
import React from 'react';
import deleteIcon from '../../../assets/images/deleteIcon.svg';
import colors from '../../../assets/styles/colors';
import MediaPreview from './MediaPreview';
import breakPoints from 'assets/styles/breakpoints';

//renders all file previews in previewArray into the 4 thumbnail squares
const UploadedMediaPreview = ({ previewArray, deleteFile }) => {
  const items = previewArray.map((preview, index) => (
    <div className="thumbnail" key={preview.uri + index}>
      <MediaPreview data={preview} />
      <button className="delete" onClick={() => deleteFile(preview, index)}>
        Delete
      </button>
    </div>
  ));
  let count = 4 - items.length;
  for (let i = 0; i < count; i = i + 1) {
    items.push(<MediaPreview key={'placeholder' + i} />);
  }

  return <Container id="thumbnailWrapper">{items}</Container>;
};
const Container = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  gap: 20px;

  #thumbnailWrapper {
    display: flex;
    justify-content: space-between;
    gap: 1vw;
  }

  .thumbnail {
    background: ${colors.lightGrey};
    width: 110px;
    height: 110px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    text-align: center;

    @media only screen and (max-width: ${breakPoints.tablet}) {
      width: 40vw;
      height: 40vw;
      min-width: 150px;
      min-height: 150px;
    }
  }

  .delete {
    background: ${colors.white};
    border-radius: 50px;
    border: none;
    width: 100px;
    font-family: Khula-Bold;
    font-size: 16px;
    color: ${colors.bluegreen};
    opacity: 0;
    position: absolute;
    cursor: pointer;
    z-index: 100;
  }

  .delete:before {
    content: '';
    background: url(${deleteIcon});
    background-size: cover;
    width: 20px;
    height: 20px;
    display: inline-block;
    position: relative;
    top: 3px;
  }
  .thumbnail:hover .delete {
    opacity: 0.85;
  }

  @media only screen and (max-width: ${breakPoints.tablet}) {
    padding: 0 20px;
    flex-wrap: wrap;
    gap: 15px;
    justify-content: space-evenly;
  }
`;

export default UploadedMediaPreview;
