import React, { useContext } from 'react';
import { GlobalContext } from 'contexts/global/GlobalContext';
import styled from '@emotion/styled';
import dotOrnament from '../../assets/images/dot-ornament.svg';
import breakPoints from 'assets/styles/breakpoints';

const HeroCard = (props) => {
  const [{ isMobile }] = useContext(GlobalContext);

  const { title, banner_image, banner_image_mobile } = props;
  return (
    <>
      {isMobile ? (
        <>
          {banner_image_mobile && (
            <Image src={banner_image_mobile.url} alt="hero"></Image>
          )}
          <MobileCardWrapper image={banner_image.url}>
            <img id="dot" src={dotOrnament} alt="dot ornament" />
            <Text>{title}</Text>
          </MobileCardWrapper>
        </>
      ) : (
        <CardWrapper image={banner_image.url}>
          <img id="dot" src={dotOrnament} alt="dot ornament" />
          <Text>{title}</Text>
        </CardWrapper>
      )}
    </>
  );
};

const CardWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin: 0px 100px;
  scroll-margin-top: 120px;
  background-image: url(${(props) => props.image});
  background-repeat: no-repeat;
  background-position: right;
  background-size: contain;

  #dot {
    width: 100px;
    position: absolute;
    left: 100px;
    margin: 100px 0px 0px;
  }
`;

const MobileCardWrapper = styled.div`
  position: relative;
  padding: 20px 20px 64px;

  #dot {
    width: 65px;
    position: absolute;
    left: 10px;
    top: -30px;
  }
`;

const Text = styled.h1`
  width: 30%;
  color: #121212;
  margin: 165px 0px 150px 40px;
  position: relative;

  &::before {
    position: absolute;
    width: 80px;
    content: ' ';
    border: 4px solid #00c2ba;
    background-color: #00c2ba;
    border-radius: 4px;
    top: -20px;

    @media only screen and (max-width: ${breakPoints.tablet}) {
      top: -15px;
      width: 50px;
    }
  }

  @media only screen and (max-width: ${breakPoints.tablet}) {
    font-size: 37px;
    line-height: 47px;
    width: 75%;
    margin: 0px;
  }
`;

const Image = styled.img`
  width: 100%;
`;

export default HeroCard;
