import React from 'react';
import { ApolloProvider } from '@apollo/client';
import { client } from './ApolloClient';
import GlobalProvider from 'contexts/global/GlobalContext';
import Routes from './routes';

function App() {
  return (
    <ApolloProvider client={client}>
      <GlobalProvider>
        <Routes />
      </GlobalProvider>
    </ApolloProvider>
  );
}

export default App;
