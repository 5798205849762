import styled from '@emotion/styled';
import React, { useState, useContext } from 'react';
import ImageSlider from '../../assets/styledComponents/ImageSlider';
import ModalCard from './ModalCard';
import { GlobalContext } from 'contexts/global/GlobalContext';

const CardImageSection = ({ mediaContent }) => {
  const [{ isMobile }] = useContext(GlobalContext);
  const [showModal, setShowModal] = useState(false);

  // Prevent background content scrolling when card modal open
  if (showModal) {
    document.body.style.overflow = 'hidden';
  } else if (!showModal) {
    document.body.style.overflow = 'unset';
  }

  const handleShowExpandedImage = () => {
    setShowModal(true);
  };

  return (
    <div>
      {showModal && (
        <ModalCard
          setShowModal={setShowModal}
          showModal={showModal}
          storyMediaContent={mediaContent}
        />
      )}
      <Container>
        <ImageSlider
          mediaContent={mediaContent}
          width="100%"
          height={isMobile ? 'auto' : '335px'}
          attributeMargin="14px"
          dotsPadding="39px"
          handleShowExpandedImage={handleShowExpandedImage}
        />
      </Container>
    </div>
  );
};

const Container = styled.div`
  :hover {
    cursor: pointer;
  }
`;

export default CardImageSection;
