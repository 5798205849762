import React from 'react';
import styled from '@emotion/styled';

const ProgressBar = ({ number }) => {
  return (
    <Wrapper>
      <Bar num={number}>
        <Step className={`${number >= 1 ? 'active' : 'inactive'}`}>
          <h4>1</h4>
        </Step>
        <LinkBar1 num={number} />
        <Step className={`${number >= 2 ? 'active' : 'inactive'}`}>
          <h4>2</h4>
        </Step>
        <LinkBar2 num={number} />
        <Step className={`${number >= 3 ? 'active' : 'inactive'}`}>
          <h4>3</h4>
        </Step>
      </Bar>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  padding: 60px 0px 0px;
`;

const Bar = styled.div`
  display: flex;
  width: 60%;
  margin: 0 auto;
  justify-content: space-between;
  position: relative;

  .active {
    background-color: #00c2ba;
    color: #ffffff;
  }

  .inactive {
    background-color: #e2f5fe;
    color: #6d767c;
  }
`;

const LinkBar1 = styled.div`
  &::after {
    content: '';
    position: absolute;
    height: 5px;
    width: 40%;
    background-color: ${(props) => (props.num > 1 ? '#00C2BA;' : '#e2f5fe;')};
    right: 50%;
    bottom: 15px;
    z-index: -1;
  }
`;

const LinkBar2 = styled.div`
  &::after {
    content: '';
    position: absolute;
    height: 5px;
    width: 40%;
    background-color: ${(props) => (props.num > 2 ? '#00C2BA;' : '#e2f5fe;')};
    left: 50%;
    bottom: 15px;
    z-index: -1;
  }
`;

const Step = styled.div`
  text-align: center;
  color: #6d767c;
  height: 40px;
  width: 40px;
  border-radius: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  h4 {
    padding: 5px;
  }
`;

export default ProgressBar;
