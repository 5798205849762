import { useQuery } from '@apollo/client';
import styled from '@emotion/styled';
import React, { useContext } from 'react';
import { GlobalContext } from 'contexts/global/GlobalContext';
import TitleHeader2WithDots from '../../assets/styledComponents/TitleHeader2WithDots';
import breakPoints from '../../assets/styles/breakpoints';
import colors from '../../assets/styles/colors';
import { GET_MISSION_PAGE } from '../../graphql/queries';
import Header3 from 'assets/styledComponents/Header3';
import LoadingSpinner from 'assets/styledComponents/LoadingSpinner';

const OurMissionPage = () => {
  const [{ isMobile }] = useContext(GlobalContext);
  const {
    loading: missionPageLoading,
    data: missionPageData,
    error: missionPageError,
  } = useQuery(GET_MISSION_PAGE);

  const goalSection =
    missionPageData && missionPageData.missionPage.goal_section;
  const objectivesSection =
    missionPageData && missionPageData.missionPage.objectives_section;

  const acknowledgementsSection =
    missionPageData && missionPageData.missionPage.acknowledgements_section;

  const objectivesItems =
    missionPageData &&
    missionPageData.missionPage.objectives_section.objectives_items;

  return (
    <>
      {missionPageLoading ? (
        <LoadingSpinner />
      ) : (
        <PageContainer>
          {!isMobile && <OurMissionBackground />}
          <OurMissionPageContainer>
            <HeaderContainer>
              {isMobile ? (
                <Header3
                  color={colors.almostBlack}
                  title={missionPageData.missionPage.title}
                />
              ) : (
                <TitleHeader2WithDots
                  color={colors.almostBlack}
                  title={missionPageData.missionPage.title}
                />
              )}
            </HeaderContainer>
            <Main>
              {isMobile ? (
                <h4 color={colors.almostBlack}>{goalSection.title}</h4>
              ) : (
                <h3 color={colors.almostBlack}>{goalSection.title}</h3>
              )}
              <ParagraphContainer>
                <Description
                  dangerouslySetInnerHTML={{ __html: goalSection.description }}
                />
              </ParagraphContainer>
              {isMobile ? (
                <h4 color={colors.almostBlack}>{objectivesSection.title}</h4>
              ) : (
                <h3 color={colors.almostBlack}>{objectivesSection.title}</h3>
              )}
              <ParagraphContainer>
                <Description
                  dangerouslySetInnerHTML={{
                    __html: objectivesSection.description,
                  }}
                />
                <UnorderedList>
                  {objectivesItems.map((item) => (
                    <ListItem key={item.id}>
                      <Description
                        dangerouslySetInnerHTML={{
                          __html: item.description,
                        }}
                      />
                    </ListItem>
                  ))}
                </UnorderedList>
              </ParagraphContainer>
              {isMobile ? (
                <h4 color={colors.almostBlack}>
                  {acknowledgementsSection.title}
                </h4>
              ) : (
                <h3 color={colors.almostBlack}>
                  {acknowledgementsSection.title}
                </h3>
              )}
              <ParagraphContainer>
                <Description
                  dangerouslySetInnerHTML={{
                    __html: acknowledgementsSection.description,
                  }}
                />
              </ParagraphContainer>
            </Main>
            {missionPageError ? <p>Error</p> : ''}
          </OurMissionPageContainer>
        </PageContainer>
      )}
    </>
  );
};

const PageContainer = styled.div`
  display: flex;
  position: relative;
  @media only screen and (max-width: ${breakPoints.mobile}) {
    display: block;
  }
`;
const OurMissionBackground = styled.div`
  position: absolute;
  background-color: ${colors.lightBlue};
  height: 100%;
  width: 50%;
  right: 0;
  z-index: -1;
`;

const OurMissionPageContainer = styled.div`
  padding: 0px 140px;
  display: flex;
  max-width: 1160px;
  margin: auto;
  @media only screen and (max-width: ${breakPoints.tablet}) {
    padding: 0 20px;
    flex-direction: column;
  }
`;

const HeaderContainer = styled.div`
  padding: 140px 0px;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 300px;
  @media only screen and (max-width: ${breakPoints.tablet}) {
    padding: 50px 0px 0px;
    flex-basis: 0;
  }
`;

const Main = styled.div`
  padding: 143px 0px 140px 100px;
  padding-left: 100px;
  background-color: ${colors.lightBlue};
  @media only screen and (max-width: ${breakPoints.tablet}) {
    background-color: ${colors.white};
    padding: 15px 0px 40px;
  }
`;

const ParagraphContainer = styled.div`
  margin-top: 30px;
  margin-bottom: 60px;
  @media only screen and (max-width: ${breakPoints.tablet}) {
    margin: 20px 0;
`;

const Description = styled.div`
  p {
    font-family: Khula-Regular;
    color: ${colors.almostBlack};
    font-size: 16px;
    line-height: 25px;
  }
`;

const UnorderedList = styled.ul`
    list-style-type: disc;
    list-style-position: outside;
}`;

const ListItem = styled.li`
  margin-top: 30px;
  margin-left: 25px;
  ::marker {
    color: ${colors.bluegreen};
    font-size: 20px;
  }
`;

export default OurMissionPage;
