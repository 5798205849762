import styled from '@emotion/styled';
import React, { useEffect, useState } from 'react';
import scroll_logo from 'assets/images/scroll_logo.svg';

const ScrollToTopButton = () => {
  const [isVisible, setIsVisible] = useState(false);

  // Show button when page is scorlled upto given distance
  const toggleVisibility = () => {
    if (window.pageYOffset < 20) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  useEffect(() => {
    let isMounted = true;
    window.addEventListener('scroll', () => isMounted && toggleVisibility());

    return () => {
      isMounted = false;
      window.removeEventListener('scroll', toggleVisibility);
    };
  }, []);

  return (
    <Container>
      <Button>{isVisible && <img src={scroll_logo} alt="" />}</Button>
    </Container>
  );
};

const Container = styled.div`
  transition: visibility 1s linear;
  display: flex;
  justify-content: center;
  transform: translatey(-120px);
`;

const Button = styled.div`
  position: fixed;
  width: 32px;

  @media screen and (min-width: 1024px) {
    transform: translatex(30px);
  }
`;

export default ScrollToTopButton;
