import styled from '@emotion/styled';
import breakPoints from 'assets/styles/breakpoints';
import colors from 'assets/styles/colors';
import React from 'react';
import CustomRefinementList from './CustomRefinementList';

const FilterByCategory = () => {
  return (
    <FilterOptionsWrapper>
      <p>You can only use one of the filter groups</p>
      <FilterGroupHeader>Covid 19 Event</FilterGroupHeader>
      <CustomRefinementList attribute="storyCategory" />

      <FilterGroupHeader>SNS Hashtag</FilterGroupHeader>
      <CustomRefinementList attribute="post.source.name" hashTag />
    </FilterOptionsWrapper>
  );
};

const FilterOptionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  background-color: white;
  width: 190px;
  z-index: 54;
  padding-top: 28px;
  transform: translate(-110px, -18px);
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.3);
  margin-left: 40px;
  p,
  li {
    font-family: 'Mulish-Regular', sans-serif;
    font-weight: 600p;
    font-size: 0.78125rem;
    letter-spacing: 0.035rem;
    padding: 0 15px;
  }

  @media only screen and (max-width: ${breakPoints.mobile}) {
    width: 200px;
    right: -175px;
  }
`;

const FilterGroupHeader = styled.p`
  font-weight: 800;
  background-color: ${colors.lightBlue};
  margin: 10px 0 15px;
  line-height: 32px;
`;

export default FilterByCategory;
