import styled from '@emotion/styled';

const Header5 = styled.h5`
  font-family: Anton;
  color: ${(props) => props.color};
  font-size: 13px;
  line-height: 17px;
  letter-spacing: 1px;
`;

export default Header5;
