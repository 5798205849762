import styled from '@emotion/styled';
import breakPoints from '../styles/breakpoints';

export const PageContainer = styled.main`
  display: block;
  background-color: ${(props) => props.backgroundColor};
  height: auto;
  min-height: calc(100vh - 800px);
  padding: 140px;

  @media only screen and (max-width: ${breakPoints.tablet}) {
    padding: 50px 20px 0;
  }
`;
