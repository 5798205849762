/*eslint-disable*/
import styled from '@emotion/styled';
import dropdownArrow from 'assets/images/icon_dropdownArrow.svg';
import React, { useState } from 'react';
import breakPoints from 'assets/styles/breakpoints';
import colors from 'assets/styles/colors';

export default function DropDownPill({ children, placeholder, width }) {
  const [showChildren, setShowChildren] = useState(false);

  return (
    <DropdownPillWrapper width={width}>
      <DropDownText
        width={width}
        onClick={() => setShowChildren(!showChildren)}
      >
        {placeholder}
        <IconContainer image={dropdownArrow}></IconContainer>
      </DropDownText>
      <ChildWrapper
        width={width}
        style={{ display: showChildren ? 'block' : 'none' }} // can't use conditional rendering because of the limitations of algoliasearch ui library
      >
        {children}
      </ChildWrapper>
    </DropdownPillWrapper>
  );
}

const DropdownPillWrapper = styled.div`
  position: relative;
  width: ${(props) => props.width}px;
  margin-left: 40px;
`;

const DropDownText = styled.div`
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.15);
  border-radius: 20px;
  background-color: white;
  padding: 0px 15px;
  position: absolute;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: 'Mulish-Regular', sans-serif;
  font-weight: 600px;
  letter-spacing: 0.035rem;
  font-size: 0.875rem;
  z-index: 55;
  width: ${(props) => props.width}px;
  @media only screen and (max-width: ${breakPoints.mobile}) {
    right: -65px;
    padding-right: 0px;
    width: ${(props) => parseInt(props.width) + 15}px;
  }
`;

const IconContainer = styled.div`
  margin-right: -15px;
  width: 36px;
  height: 36px;
  border-radius: 50%;

  background-image: url(${(props) => props.image});
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
  :hover {
    background-color: #e2f5fe80;
  }
  :active {
    background-color: ${colors.lightBlue};
  }
  @media only screen and (max-width: ${breakPoints.mobile}) {
    left: -50px;
  }
`;

const ChildWrapper = styled.div`
  display: flex;
  width: ${(props) => props.width}px;
  margin-left: 40px;
`;
