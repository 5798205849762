import styled from '@emotion/styled';
import breakPoints from 'assets/styles/breakpoints';
import colors from 'assets/styles/colors';
import React from 'react';

const ShareYourStory = (props) => {
  const { image, description } = props;
  return (
    <Card>
      {image ? (
        <CardImage image={image.url}></CardImage>
      ) : (
        <CardImage></CardImage>
      )}
      <CardContents>
        <CardText dangerouslySetInnerHTML={{ __html: description }}></CardText>
      </CardContents>
    </Card>
  );
};

const Card = styled.div`
  background-color: white;
  padding: 20px;
  box-shadow: 0px -10px 50px rgba(0, 0, 0, 0.05),
    0px 60px 60px 5px rgba(49, 49, 49, 0.1);
`;

const CardImage = styled.div`
  width: 100%;
  height: 200px;
  background: ${(props) => (props.image ? `url(${props.image})` : '#00c2ba')};
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  @media only screen and (max-width: ${breakPoints.mobile}) {
    height: 145px;
  }
`;

const CardContents = styled.div`
  text-align: center;
  margin-top: 20px;

  @media only screen and (max-width: ${breakPoints.tablet}) {
      font-family: Mulish-Regular
      font-weight: 600;
      font-size: 12.5px;
    }
`;

const CardText = styled.p`
  p span {
    font-family: Khula-SemiBold;
    color: ${colors.darkNavy};
    font-size: 16px;
    line-height: 25px;
  }
`;

export default ShareYourStory;
