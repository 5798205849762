import styled from '@emotion/styled';
import React from 'react';
import dotOrnament from '../../assets/images/dot-ornament.svg';

const TitleHeader2WithDots = ({ title, color }) => {
  return (
    <HeaderWrapper>
      <StyledHeader2 titleColor={color}>{title}</StyledHeader2>
      <img id="dot" src={dotOrnament} alt="dot ornament" />
    </HeaderWrapper>
  );
};

const HeaderWrapper = styled.div`
  position: relative;
  margin-bottom: 50px;

  #dot {
    width: 90px;
    position: absolute;
    left: -40px;
    top: -50px;
    z-index: 0;
  }
`;

const StyledHeader2 = styled.h2`
  width: auto;
  color: ${(props) => props.titleColor};
  font-family: Anton;
  font-size: 37px;
  letter-spacing: 1px;
  line-height: 47px;
  z-index: 2;

  &::before {
    position: absolute;
    width: 80px;
    content: ' ';
    border: 4px solid #00c2ba;
    background-color: #00c2ba;
    border-radius: 4px;
    top: -20px;
    z-index: inherit;
  }

  @media only screen and (max-width: 1024px) {
    #dot {
      display: none;
    }
`;

export default TitleHeader2WithDots;
