import styled from '@emotion/styled';
import Video from 'assets/styledComponents/Video';
import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import StoryTypes from 'types/StoryTypes';
import videoPlayIcon from '../../../assets/images/playVideoIcon.svg';
import videoPlaceHolder from '../../../assets/images/video_placeholder_md.svg';
import videoPlayIconHover from '../../../assets/images/video_play_icon_hover.svg';
import colors from '../../../assets/styles/colors';

const StoryCarousel = ({ storyMediaContent, handleShowExpandedCard }) => {
  const settings = {
    speed: 500,
    arrows: true,
    infinite: false,
    lazyLoad: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: true,
  };

  const renderSocialMediaContent = () => {
    const res = [];
    if (storyMediaContent.media.image !== '') {
      let img = storyMediaContent.media.image;
      res.push(
        <StyledImg
          onClick={handleShowExpandedCard}
          src={img}
          key={img}
          alt={'Post image'}
        />
      );
    }
    if (storyMediaContent.media.video) {
      let videoLink = storyMediaContent.media.video;
      res.push(
        <StyledVideoContainer key={videoLink} onClick={handleShowExpandedCard}>
          <Video type={storyMediaContent.type} src={videoLink} />
        </StyledVideoContainer>
      );
    }
    return res;
  };

  const renderStoryMedia = () =>
    storyMediaContent &&
    storyMediaContent.media.map((obj) =>
      obj.image ? (
        <StyledImg
          onClick={handleShowExpandedCard}
          src={obj.image.url}
          key={obj.image.id}
          alt={obj.image.name}
        />
      ) : (
        <StyledVideoContainer key={obj.id} onClick={handleShowExpandedCard}>
          <StyledImg id="place-holder" src={videoPlaceHolder} alt={'video'} />
          <PlayIcon
            id="play-icon"
            image={videoPlayIcon}
            imageHover={videoPlayIconHover}
            alt={'play icon'}
          />
        </StyledVideoContainer>
      )
    );
  return (
    <StyledSlider
      {...settings}
      color={colors}
      handleShowExpandedCard={handleShowExpandedCard}
    >
      {storyMediaContent &&
      storyMediaContent.type === StoryTypes.SOCIAL_MEDIA_POST
        ? renderSocialMediaContent()
        : renderStoryMedia()}
    </StyledSlider>
  );
};

const StyledVideoContainer = styled.div`
  position: relative;
  width: 100%;
  aspect-ratio: 1/1;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;

  img {
    position: absolute;
  }

  :hover {
    cursor: pointer;
  }
`;

const StyledImg = styled.img`
  width: 100%;
  object-fit: cover;
  aspect-ratio: 1/1;
  z-index: 0;
  :hover {
    cursor: pointer;
  }
`;

const PlayIcon = styled.button`
  object-fit: cover;
  width: 150px;
  height: 150px;
  z-index: 1;
  border: none;
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
  background-image: url(${(props) => props.image});
  :hover {
    background-image: url(${(props) => props.imageHover});
  }
`;

const StyledSlider = styled(Slider)`
  width: 100%;

  .slick-slide {
    & > div {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  button {
    z-index: 1;

    &:before {
      font-size: 2.5rem;
    }
  }

  .button {
    display: block !important;
    background-color: red;
  }

  /* Style for arrows */
  &:hover {
    .slick-arrow {
      display: block !important;
    }
  }

  .slick-arrow {
    display: block !important;

    &.slick-prev {
      left: 1rem;
    }
    &.slick-next {
      right: 2.5rem;
    }
  }

  /* Style for dots */
  .slick-dots {
    padding-bottom: 30px;
    bottom: 0;

    & li {
      width: 10px;
      height: 10px;
      margin: 0 4.5px;
    }

    & li button {
      width: 10px;
      height: 10px;
    }

    & li button:before {
      width: 10px;
      height: 10px;
      font-size: 10px;
      opacity: 1;
      color: white;
    }
    & li.slick-active button:before {
      opacity: 1;
      color: ${(props) => props.color.lightYellow};
    }
  }
`;

export default StoryCarousel;
