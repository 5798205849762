import styled from '@emotion/styled';
import React, { useEffect, useState, useContext } from 'react';
import * as tus from 'tus-js-client';
import CropImage from '../CropImage';
import UploadedMediaPreview from './UploadedMediaPreview';
import { GlobalContext } from 'contexts/global/GlobalContext';
import { useMutation } from '@apollo/client';
import { UPDATE_STORY } from 'graphql/mutations';
import { UPLOAD_IMAGE } from '../../../graphql/mutations';
import breakPoints from 'assets/styles/breakpoints';

const StepTwo = ({
  changeStep,
  previewArray,
  setPreviewArray,
  isImageCropView,
  setIsImageCropView,
  storySubmission,
  setStorySubmission,
}) => {
  const [{ isMobile }] = useContext(GlobalContext);
  const [upImg, setUpImg] = useState();

  //for vimeo video upload
  const [uploadComplete, setUploadComplete] = useState(true);
  const [error, setError] = useState(null);

  //for dropzone
  const [itemDrag, setItemDrag] = useState(false);
  const [maxUploadReached, setMaxUploadReached] = useState(false);
  let validFileImageTypes = ['image/jpeg', 'image/jpg', 'image/png'];
  let validFileVideoTypes = [
    'video/mp4',
    'video/mov',
    'video/wmv',
    'video/flv',
    'video/quicktime',
  ];

  //for uploading media to story
  const [updateStory] = useMutation(UPDATE_STORY);
  const [uploadImage] = useMutation(UPLOAD_IMAGE);

  const handleNextClick = () => {
    if (uploadComplete) {
      changeStep(3);
      sendToStrapi();
      setPreviewArray([]);
    }
  };

  /* functions for the drag and drop zone */
  const dragOverHandler = (e) => {
    e.preventDefault();
    setItemDrag(true);
  };

  const dragLeaveHandler = () => {
    setItemDrag(!itemDrag);
  };

  useEffect(() => {
    setMaxUploadReached(previewArray.length === 4);
  }, [previewArray]);

  /* functions to handle vimeo processes */

  const handleVideoUpload = async (item) => {
    addItemToPreviews(item);
    setUploadComplete(false);
    const auth = await fetch('https://api.vimeo.com/me/videos', {
      method: 'POST',
      headers: {
        Authorization: `bearer ${process.env.REACT_APP_VIMEO_ACCESS_TOKEN}`,
        'Content-Type': 'application/json',
        Accept: ' application/vnd.vimeo.*+json;version=3.4',
      },
      body: JSON.stringify({
        upload: {
          approach: 'tus',
          size: `${item.file.size}`,
        },
        privacy: {
          view: 'unlisted',
          embed: 'public',
          download: 'true',
        },
      }),
    });

    // Use the upload link to send several PATCH's until complete
    const authJSON = await auth.json();
    const uploadLink = authJSON.upload.upload_link;

    const upload = new tus.Upload(item.file, {
      uploadUrl: uploadLink,
      //exponential backoff
      retryDelays: [0, 3000, 5000, 10000, 20000],
      //Verify if we uploaded successfully or progress so far
      onError: (error) => {
        // set error for this file
        setError(error);
      },
      onProgress: (bytesUploaded, bytesTotal) => {
        //calc and display % complete for this file
        const percentage = ((bytesUploaded / bytesTotal) * 100).toFixed(2);
        item.uri = authJSON.uri;
        item.progress = percentage;
        setPreviewArray([...previewArray, item]);
        if (percentage < 100.0) {
          setUploadComplete(false);
        }
      },
      //full upload for this file is complete
      onSuccess: () => {
        item.link = authJSON.player_embed_url;
        setUploadComplete(true);
      },
    });
    item.tusUpload = upload;
    upload.start();
  };

  //deletes fully uploaded video from vimeo
  const handleVimeoDeleteVideo = async (data) => {
    const vidId = Number(data.uri.split('/')[2]);
    await fetch(`https://api.vimeo.com/videos/${vidId}`, {
      method: 'DELETE',
      headers: {
        Authorization: `bearer ${process.env.REACT_APP_VIMEO_ACCESS_TOKEN}`,
      },
    }).then(() => {
      alert('vimeo video deleted');
    });
  };

  /* functions to handle uploading/selecting files */

  const uploadMediaFile = (selectedFile) => {
    if (!selectedFile) {
      return;
    }

    let type = selectedFile.type.split('/')[0];
    const objectUrl = URL.createObjectURL(selectedFile);

    const item = {
      type: type,
      file: selectedFile,
      thumbnailUrl: objectUrl,
      uri: null,
      tusUpload: null,
      progress: 0,
    };

    if (type === 'image') {
      handleImageUpload(item);
    } else if (type === 'video') {
      handleVideoUpload(item);
    }
  };

  const addItemToPreviews = (item) => {
    if (!item) {
      return;
    }
    setPreviewArray([...previewArray, item]);
  };

  //sets selectedFile when file is selected after user clicks Browse button
  const onFileSelected = (e) => {
    if (e.target.files.length === 0) {
      return;
    }
    let fileType = e.target.files[0].type;
    //set crop view only for image files
    if (validFileImageTypes.includes(fileType)) {
      if (e.target.files && e.target.files.length > 0) {
        const reader = new FileReader();
        reader.addEventListener('load', () => setUpImg(reader.result));
        reader.readAsDataURL(e.target.files[0]);
      }
      setIsImageCropView(true);
    } else if (validFileVideoTypes.includes(fileType)) {
      uploadMediaFile(e.target.files[0]);
    }
  };

  const handleChancelImgCropClick = (e) => {
    e.preventDefault();
    setIsImageCropView(false);
  };

  // set the selected file to croped image if the user clicks the "Crop" button at the image crop view
  const handleCropClick = (canvas, crop) => {
    if (!crop || !canvas) {
      return;
    }
    canvas.toBlob(uploadMediaFile, 'image/png', 1);
    setIsImageCropView(false);
  };

  //sets selectedFile when file is dropped into drop zone
  const dropHandler = (e) => {
    e.preventDefault();
    setItemDrag(!itemDrag);
    if (!e.dataTransfer.files || e.dataTransfer.files.length === 0) {
      return;
    }
    let fileType = e.dataTransfer.files[0].type;
    //set crop view only for image files
    if (validFileImageTypes.includes(fileType)) {
      const reader = new FileReader();
      reader.addEventListener('load', () => setUpImg(reader.result));
      reader.readAsDataURL(e.dataTransfer.files[0]);
      setIsImageCropView(true);
    } else if (validFileVideoTypes.includes(fileType)) {
      uploadMediaFile(e.dataTransfer.files[0]);
    } else {
      alert('This is not an video/image file');
    }
  };

  //deletes the file with the given index
  const deleteFile = (data, index) => {
    if (data.type === 'video') {
      if (0 < data.progress && data.progress < 100) {
        data.tusUpload.abort(true).then(() => {
          alert('video upload aborted');
        });
      } else if (data.progress == 100) {
        handleVimeoDeleteVideo(data);
      }
    }
    let tmp = [...previewArray];
    tmp.splice(index, 1);
    setPreviewArray(tmp);
  };

  const handlePreviousClick = (e) => {
    e.preventDefault();
    changeStep(1);
  };

  const handleImageUpload = async (item) => {
    setUploadComplete(false);
    item.imageLoading = true;
    addItemToPreviews(item);

    const uploadImageResponse = await uploadImage({
      variables: {
        file: item.file,
      },
    });

    item.file.id = uploadImageResponse.data.upload.id;
    setUploadComplete(true);
    item.imageLoading = false;
    setPreviewArray([...previewArray, item]);
  };

  const sendToStrapi = async () => {
    //default storyData object
    const storyData = {
      storyMediaContent: [],
    };

    previewArray.forEach((preview) => {
      if (preview.type === 'image') {
        let image = {
          __typename: 'ComponentStoryMediaContentImage',
          image: preview.file.id,
        };
        storyData.storyMediaContent.push(image);
      } else if (preview.type === 'video') {
        let video = {
          __typename: 'ComponentStoryMediaContentVideoLink',
          link: preview.link,
        };
        storyData.storyMediaContent.push(video);
      }
    });
    //submit to BE
    updateStory({
      variables: {
        id: storySubmission.id,
        data: storyData,
      },
    });
    setStorySubmission(null);
  };

  return (
    <>
      {isImageCropView ? (
        <CropImage
          upImg={upImg}
          handleChancelImgCropClick={handleChancelImgCropClick}
          handleCropClick={handleCropClick}
        />
      ) : (
        <StepWrapper>
          <h5 id="stepNumber">Step 2</h5>
          <h4 id="stepTitle">Find Your Photo or Video</h4>
          <p id="subtitle">Please share your story of the pandemic with us!</p>

          {isMobile ? (
            <UploadButtonContainer>
              <UploadButton>
                <label htmlFor="imageUpload">Upload Your Photo Or Video</label>
                <input
                  type="file"
                  id="imageUpload"
                  accept="image/*, video/*"
                  onChange={onFileSelected}
                  disabled={maxUploadReached}
                ></input>
              </UploadButton>
              <p>20mb Max, 4 pictures or videos Max</p>
            </UploadButtonContainer>
          ) : (
            <>
              <Dropzone
                onDragOver={dragOverHandler}
                onDragLeave={dragLeaveHandler}
                onDrop={dropHandler}
                itemDraggedOver={itemDrag}
                maxUploadReached={maxUploadReached}
              >
                <p id="dropzoneTitle">
                  Drop Your Files Here, Or{' '}
                  <label htmlFor="imageUpload" id="browse">
                    Browse
                  </label>
                  <input
                    type="file"
                    id="imageUpload"
                    accept="image/*, video/*"
                    onChange={onFileSelected}
                  ></input>
                </p>
                <p className="dropzoneSubtitle">
                  20mb Max, 4 pictures or videos Max
                </p>
              </Dropzone>
              <DropzoneMaxUpload maxUploadReached={maxUploadReached}>
                <p>You have uploaded four files.</p>
                <p>If you want to upload more, please delete the other.</p>
                <p className="dropzoneSubtitle">
                  20mb Max, 4 pictures or videos Max
                </p>
              </DropzoneMaxUpload>
            </>
          )}
          <UploadedMediaPreview
            previewArray={previewArray}
            deleteFile={deleteFile}
          />
          <UploadMessages>
            {error && <p>There was an error: {error}</p>}
          </UploadMessages>
          <NavigationButtons isComplete={uploadComplete}>
            <button id="previous" onClick={handlePreviousClick}>
              Previous
            </button>
            <button id="next" onClick={handleNextClick}>
              Next
            </button>
          </NavigationButtons>
        </StepWrapper>
      )}
    </>
  );
};

const StepWrapper = styled.div`
  padding: 30px 70px 30px;
  #stepNumber {
    color: #00c2ba;
    margin-top: 30px;
  }

  #stepTitle {
    margin-top: 10px;
  }

  #subtitle {
    font-family: Khula-Regular;
    font-size: 16px;
    line-height: 25px;
    color: #0f374a;
    margin-top: 15px;
  }

  @media only screen and (max-width: ${breakPoints.tablet}) {
    padding: 30px 0px;

    #stepNumber {
      padding: 0 50px;
      margin: 0px;
    }

    #stepTitle {
      padding: 3px 50px 0px;
      margin: 0px;
    }

    #subtitle {
      padding: 7px 50px 0px;
      margin: 0px;
    }
  }
`;

const Dropzone = styled.div`
  margin-top: 30px;
  background-color: #e2f5fe;
  height: 240px;
  display: ${(props) => (props.maxUploadReached ? 'none' : 'flex')};
  flex-direction: column;
  justify-content: center;
  align-items: center;
  opacity: ${(props) => (props.itemDraggedOver ? '0.5' : '1')};

  #dropzoneTitle {
    font-family: Khula-Bold;
    color: #0f374a;
    font-size: 16px;
  }

  #browse {
    color: #00c2ba;
    background: none;
    border: none;
    cursor: pointer;
    :hover {
      opacity: 0.7;
    }
  }

  .dropzoneSubtitle {
    font-family: Mulish-Regular;
    color: #6d767c;
    font-size: 12px;
  }

  #imageUpload {
    display: none;
  }
`;
const DropzoneMaxUpload = styled.div`
  display: ${(props) => (props.maxUploadReached ? 'flex' : 'none')};
  margin-top: 30px;
  height: 240px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #ededed;

  p {
    font-family: Khula-Bold;
    color: #000000;
    font-size: 16px;
    line-height: 25px;
    text-align: center;
    letter-spacing: -0.25px;
    text-transform: capitalize;
  }

  .dropzoneSubtitle {
    font-family: Mulish-Regular;
    color: #6d767c;
    font-size: 12px;
  }
`;

const UploadButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 15px 40px 20px;

  p {
    font-family: Mulish-Regular;
    color: #6d767c;
    font-size: 12.5px;
    line-weight: 17px;
  }
`;

const UploadButton = styled.button`
  width: 100%;
  max-width: 320px;
  height: 45px;
  border: none;
  margin-bottom: 10px;
  background: #00c2ba;
  border-radius: 40px;

  label {
    color: white;
    font-family: Khula-Bold;
    font-size: 16px;
    text-align: center;
    letter-spacing: -0.25px;
  }

  #imageUpload {
    display: none;
  }
`;

const UploadMessages = styled.div`
  font-family: Khula-Regular;
  font-size: 14px;
  color: #0f374a;
  margin-top: 15px;
`;

const NavigationButtons = styled.div`
  margin: 40px 0px 30px;
  display: flex;
  justify-content: center;

  button {
    font-family: Khula-Bold;
    font-size: 16px;
    line-height: 25px;
    text-align: center;
    width: 148px;
    height: 40px;
    border-radius: 50px;
    cursor: pointer;
    padding-top: 5px;
  }

  #previous {
    color: #fab550;
    background: #ffffff;
    border: 1px solid #fab550;
    margin-right: 10px;
  }

  #next {
    color: #ffffff;
    background: ${(props) => (props.isComplete ? '#fab550' : ' #EBEEF0')};
    cursor: ${(props) => (props.isComplete ? 'pointer' : 'default')};
    border: none;
  }
`;
export default StepTwo;
