const storyCategories = [
  'Vaccine',
  'Face masks',
  'Resilience',
  'Stay-home activities',
  'Social Distancing',
  'Socialization in the pandemic',
  'Work from home',
  'School in the pandemic',
  'COVID-19 symptoms',
  'Mental health',
  'Family health and wellbeing',
];

export default storyCategories;
