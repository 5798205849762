import styled from '@emotion/styled';
import React from 'react';
import Slider from 'react-slick';
import StoryTypes from 'types/StoryTypes';
import Video from '../../assets/styledComponents/Video';
import colors from '../../assets/styles/colors';

const ExpandedStoryMediaCarousel = ({ mediaContent }) => {
  const settings = {
    speed: 500,
    arrows: true,
    infinite: false,
    lazyLoad: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: true,
  };

  const renderSocialMediaContent = () => {
    const res = [];
    if (mediaContent.media.image !== '') {
      let img = mediaContent.media.image;
      res.push(<Image src={img} key={img} alt={'Post image'} />);
    }
    if (mediaContent.media.video) {
      let video = mediaContent.media.video;
      res.push(<Video type={mediaContent.type} key="Post video" src={video} />);
    }
    return res;
  };

  const renderImages = () =>
    mediaContent.media.map((item) =>
      item.image ? (
        <Image
          src={item.image.url}
          key={item.image.url}
          alt={item.image.name}
        ></Image>
      ) : (
        <Video type={mediaContent.type} key={item.id} src={item.link} />
      )
    );

  return (
    <StyledSlider {...settings}>
      {mediaContent && mediaContent.type === StoryTypes.SOCIAL_MEDIA_POST
        ? renderSocialMediaContent()
        : renderImages()}
    </StyledSlider>
  );
};

const StyledSlider = styled(Slider)`
.slick-slide {
    & > div {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 800px;
  max-width: 65vw;
  height: 720px;
  max-height: 100vh;
    }
  }

  button {
    z-index: 1;

    &:before {
      font-size: 36px;
      filter: drop-shadow(0 0 8px rgba(49, 49, 49, 0.5));
    }
  }

  .button {
    display: none;
  }
/* Style for arrows */

  .slick-arrow {
    display: block !important;
    width: 36px;
    height: 36px;
    z-index: 50;

    &.slick-prev {
      left: 20px;
    }
    &.slick-next {
      right: 20px;
    }
  }

/* Style for dots */
.slick-dots {
  padding-bottom: 3rem;
  z-index: 15;

 & li {
   margin: 0 0.2rem;
   width: 10px;
   height: 10px;}

 & li button {
  width: 10px;
  height: 10px;
}

  & li button:before {
    width: 10px;
    height: 10px;
    font-size: 10px;
    opacity: 1;
    color: white;
  }
 
  & li.slick-active button:before {
    opacity: 1;
    color: ${colors.lightYellow};
  }`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

export default ExpandedStoryMediaCarousel;
