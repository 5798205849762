import { useMutation } from '@apollo/client';
import styled from '@emotion/styled';
import calendarIcon from 'assets/images/calendar.svg';
import breakPoints from 'assets/styles/breakpoints';
import colors from 'assets/styles/colors';
import { CREATE_STORY, UPDATE_STORY } from 'graphql/mutations';
import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import postalCodes from '../../../utils/postalCodes';
import storyCategories from '../../../utils/storyCategories';
import SelectField from './SelectField';

const StepOne = ({
  changeStep,
  close,
  form,
  setForm,
  setStorySubmission,
  storySubmission,
}) => {
  const { title, story, name, date, postalCode, storyCategory } = form;
  const [complete, setComplete] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [region, setRegion] = useState(postalCode);
  const [category, setCategory] = useState(storyCategory);

  const [createStory] = useMutation(CREATE_STORY, {
    onCompleted: (res) => setStorySubmission(res.createStory.story),
  });
  const [updateStory] = useMutation(UPDATE_STORY, {
    onCompleted: (res) => setStorySubmission(res.updateStory.story),
  });

  useEffect(() => {
    if (storySubmission) {
      Object.entries(storySubmission).forEach(([key, value]) => {
        document.getElementById(key)
          ? (document.getElementById(key).value = value)
          : null;
      });
      setStartDate(new Date(storySubmission.date));
      setForm((prev) => ({
        ...prev,
        date: startDate,
      }));
    }
    //eslint-disable-next-line
  }, []);

  const updateForm = (e) => {
    setForm((prev) => ({
      ...prev,
      date: new Date(startDate).toISOString(),
      [e.target.name]: e.target.value,
    }));
  };

  //converts date chosen from date picker to string, sets date in form
  const handleDateChange = (date) => {
    setStartDate(date); // set today's date by default
    let dateString = new Date(date);
    setForm((prev) => ({
      ...prev,
      date: dateString,
    }));
  };

  const handleRegionChange = (value) => {
    setRegion(value);
    setForm((prev) => ({
      ...prev,
      postalCode: value,
    }));
  };

  const handleCategoryChange = (value) => {
    setCategory(value);
    setForm((prev) => ({
      ...prev,
      storyCategory: value,
    }));
  };

  //sets complete to true if form title and story input fields are filled out
  useEffect(() => {
    if (title && story) {
      setComplete(true);
    } else {
      setComplete(false);
    }
    // eslint-disable-next-line
  }, [form]);

  const sendToStrapi = async () => {
    //default storyData object
    const storyData = {
      title,
      name,
      date,
      postalCode,
      storyCategory,
      yourStory: story,
      published_at: null,
      storyMediaContent: [],
    };

    //submit to BE
    if (storySubmission) {
      updateStory({
        variables: {
          id: storySubmission.id,
          data: storyData,
        },
      });
    } else {
      createStory({
        variables: {
          data: storyData,
        },
      });
    }
  };

  const handleNextClick = (e) => {
    e.preventDefault();
    sendToStrapi();
    changeStep(2);
  };

  const renderDatePicker = () => {
    const CustomInput = React.forwardRef(({ value, onClick }, ref) => (
      // eslint-disable-next-line
      <StyledInput role="button" tabIndex={0} onClick={onClick} ref={ref}>
        <span>{value ? value : 'Date'}</span>
        <img src={calendarIcon} alt="calendar"></img>
      </StyledInput>
    ));
    CustomInput.displayName = 'CustomInput';

    return (
      <DatePicker
        id="date"
        selected={startDate}
        onChange={handleDateChange}
        dateFormat="yyyy.MM.dd"
        isClearable
        customInput={<CustomInput />}
        required
      />
    );
  };

  return (
    <StepWrapper>
      <h5 id="stepNumber">Step 1</h5>
      <h4 id="stepTitle">Add Your Stories</h4>
      <p id="subtitle">Please share your story of the pandemic with us!</p>
      <Form id="uploadForm" onSubmit={handleNextClick}>
        <Input
          type="text"
          id="title"
          name="title"
          placeholder="Title*"
          onChange={updateForm}
          required
        ></Input>
        <NameDateRow>
          <Input
            type="text"
            id="name"
            name="name"
            placeholder="Your Name"
            onChange={updateForm}
          ></Input>
          {renderDatePicker()}
        </NameDateRow>
        <SelectField
          summary="Search your region by postal code"
          options={postalCodes}
          activeOption={region}
          startState={region}
          handleSelectOption={(value) => handleRegionChange(value)}
        />
        <SelectField
          summary="This story is about"
          options={storyCategories}
          activeOption={category}
          startState={category}
          handleSelectOption={(value) => handleCategoryChange(value)}
        />
        <textarea
          type="text"
          id="yourStory"
          name="story"
          placeholder="Your Story*"
          form="uploadForm"
          required
          onChange={updateForm}
        ></textarea>
        <NavigationButtons isComplete={complete}>
          <button id="cancel" onClick={close}>
            Cancel
          </button>
          <button type="submit" id="next">
            Next
          </button>
        </NavigationButtons>
      </Form>
    </StepWrapper>
  );
};

const StepWrapper = styled.div`
  padding: 30px 70px;
  #stepNumber {
    color: #00c2ba;
    margin-top: 30px;
  }

  #stepTitle {
    margin-top: 10px;
  }

  #subtitle {
    font-family: Khula-Regular;
    font-size: 16px;
    line-height: 25px;
    color: #0f374a;
    margin-top: 15px;
  }

  @media only screen and (max-width: ${breakPoints.tablet}) {
    padding: 30px 0px;

    #stepNumber {
      padding: 0 50px;
      margin: 0px;
    }

    #stepTitle {
      padding: 3px 50px 0px;
      margin: 0px;
    }

    #subtitle {
      padding: 7px 50px 0px;
      margin: 0px;
    }
  }
`;

const Form = styled.form`
  * {
    box-sizing: border-box;
  }

  input::placeholder,
  textarea::placeholder {
    color: #a9a9a9;
  }

  input:focus,
  textarea:focus {
    outline: none !important;
    border: 2px solid #00c2ba;
  }

  textarea {
    font-family: Khula-Regular;
    font-size: 16px;
    margin-top: 10px;
    border: 1px solid #a0a8ac;
    border-radius: 5px;
    width: 100%;
    height: 261px;
    padding: 10px 0px 0px 15px;
    resize: none;
  }

  .react-datepicker__close-icon {
    display: none;
  }

  .react-datepicker__day--keyboard-selected,
  .react-datepicker__day--selected {
    background-color: #00c2ba;
  }

  @media only screen and (max-width: ${breakPoints.tablet}) {
    padding: 10px 20px 0px;
  }
`;

const Input = styled.input`
  font-family: Khula-Regular;
  font-size: 16px;
  margin-top: 10px;
  border: 1px solid #a0a8ac;
  border-radius: 5px;
  height: 35px;
  width: 100%;
  padding: 5px 0px 0px 15px;
`;

const NameDateRow = styled.div`
  display: grid;
  grid-template-columns: 290px auto;
  column-gap: 10px;

  @media only screen and (max-width: ${breakPoints.tablet}) {
    display: flex;
    flex-wrap: wrap;
  }
`;

const NavigationButtons = styled.div`
  margin: 20px 0px 30px;
  display: flex;
  justify-content: center;

  button {
    font-family: Khula-Bold;
    font-size: 16px;
    line-height: 25px;
    text-align: center;
    width: 148px;
    height: 40px;
    border-radius: 50px;
    cursor: pointer;
    padding-top: 5px;
  }

  #cancel {
    color: #fab550;
    background: #ffffff;
    border: 1px solid #fab550;
    margin-right: 10px;
  }

  #next {
    color: #ffffff;
    background: ${(props) => (props.isComplete ? '#fab550' : '#EBEEF0;')};
    cursor: ${(props) => (props.isComplete ? 'pointer' : 'default')};
    border: none;
  }

  @media only screen and (max-width: ${breakPoints.tablet}) {
    margin: 20px;
  }
`;

const StyledInput = styled.div`
  font-family: Khula-Regular;
  font-size: 16px;
  margin-top: 10px;
  border: 1px solid #a0a8ac;
  border-radius: 5px;
  height: 35px;
  width: 100%;
  padding: 8px 10px 0px 15px;
  display: flex;
  justify-content: space-between;
  color: ${colors.almostBlack};

  img {
    width: 20px;
    height: 20px;
  }

  :focus {
    border: 2px solid #00c2ba;
  }
`;

export default StepOne;
