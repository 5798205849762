const postalCodes = [
  'V0A - Upper Columbia Region',
  'V0B - East Kootenay',
  'V0C - Northern British Columbia',
  'V0E - High Country, Shuswap and Northeast Okanagan',
  'V0G - West Kootenay ',
  'V0H - Southeast Okanagan and Boundary Country',
  'V0J - Omineca and Yellowhead',
  'V0K - Cariboo, Fraser Canyon and Western Okanagan',
  'V0L - Chilcotin',
  'V0M - Harrison Lake Region',
  'V0N - North Vancouver Island, Sunshine Coast, Sea-to-Sky',
  'V0P - North Central Vancouver Island and Discovery Island',
  'V0R - Central and South Vancouver Island',
  'V0S -  Juan de Fuca Shore',
  'V0T - Inside Passage and Haida Gwaii',
  'V0V - Lower Skeena Region',
  'V0W - Atlin Region',
  'V0X - Similkameen Region',
  'V1A - Kimberley',
  'V1B - Vernon',
  'V1C - Cranbrook',
  'V1E - Salmon Arm',
  'V1G - Dawson Creek',
  'V1H - Vernon',
  'V1J - Fort St. John',
  'V1K - Merritt',
  'V1L - Nelson',
  'V1M - Langley Township',
  'V1N - Castlegar',
  'V1P - Kelowna',
  'V1R - Trail',
  'V1S - Kamloops',
  'V1T - Vernon',
  'V1V - Kelowna',
  'V1W - Kelowna',
  'V1X - Kelowna',
  'V1Y - Kelowna',
  'V1Z - West Kelowna',
  'V2A - Penticton',
  'V2B - Kamloops',
  'V2C - Kamloops',
  'V2E - Kamloops',
  'V2G - Williams Lake',
  'V2H - Kamloops',
  'V2J - Quesnel',
  'V2K - Prince George',
  'V2L - Prince George',
  'V2M - Prince George',
  'V2N - Prince George',
  'V2P - Chilliwack',
  'V2R - Chilliwack',
  'V2S - Abbotsford',
  'V2T - Abbotsford',
  'V2V - Mission',
  'V2W - Maple Ridge',
  'V2X - Maple Ridge',
  'V2Y - Langley Township',
  'V2Z - Langley Township',
  'V3A - Langley Township',
  'V3B - Port Coquitlam',
  'V3C - Port Coquitlam',
  'V3E - Coquitlam',
  'V3G - Abbotsford',
  'V3H - Port Moody',
  'V3J - Coquitlam',
  'V3K - Coquitlam',
  'V3L - New Westminster',
  'V3M - New Westminster',
  'V3N - Burnaby',
  'V3R - Surrey',
  'V3S - Surrey',
  'V3T - Surrey',
  'V3V - Surrey',
  'V3W - Surrey',
  'V3X - Surrey',
  'V3Y - Surrey',
  'V3Z - Surrey',
  'V4A - Surrey',
  'V4B - White Rock',
  'V4C - Delta',
  'V4E - Delta',
  'V4G - Delta',
  'V4K - Delta',
  'V4L - Delta',
  'V4M - Delta',
  'V4N - Surrey',
  'V4P - Surrey',
  'V4R - Maple Ridge',
  'V4S - Mission',
  'V4T - West Kelowna',
  'V4V - Winfield',
  'V4W - Langley Township',
  'V4X - Abbotsford',
  'V4Z - Chilliwack',
  'V5A - Burnaby',
  'V5B - Burnaby',
  'V5C - Burnaby',
  'V5E - Burnaby',
  'V5G - Burnaby',
  'V5H - Burnaby',
  'V5J - Burnaby',
  'V5K - Vancouver',
  'V5L - Vancouver',
  'V5M - Vancouver',
  'V5N - Vancouver',
  'V5P - Vancouver',
  'V5R - Vancouver',
  'V5S - Vancouver',
  'V5T - Vancouver ',
  'V5V - Vancouver',
  'V5W - Vancouver',
  'V5X - Vancouver',
  'V5Y - Vancouver ',
  'V5Z - Vancouver',
  'V6A - Vancouver',
  'V6B - Vancouver',
  'V6C - Vancouver ',
  'V6E - Vancouver',
  'V6G - Vancouver',
  'V6H - Vancouver ',
  'V6J - Vancouver',
  'V6K - Vancouver',
  'V6L - Vancouver',
  'V6M - Vancouver',
  'V6N - Vancouver ',
  'V6P - Vancouver',
  'V6R - Vancouver ',
  'V6S - Vancouver ',
  'V6T - Vancouver',
  'V6V - Richmond',
  'V6W - Richmond',
  'V6X - Richmond',
  'V6Y - Richmond ',
  'V6Z - Vancouver',
  'V7A - Richmond',
  'V7B - Richmond',
  'V7C - Richmond',
  'V7E - Richmond',
  'V7G - North Vancouver (district municipality)',
  'V7H - North Vancouver (district municipality)',
  'V7J - North Vancouver (district municipality)',
  'V7K - North Vancouver (district municipality)',
  'V7L - North Vancouver (city)',
  'V7M - North Vancouver (city)',
  'V7N - North Vancouver (city)',
  'V7P - North Vancouver (city)',
  'V7R - North Vancouver (district municipality)',
  'V7S - West Vancouver',
  'V7T - West Vancouver',
  'V7V - West Vancouver',
  'V7W - West Vancouver',
  'V7X - Vancouver',
  'V7Y - Vancouver',
  'V8A - Powell River',
  'V8B - Squamish',
  'V8C - Kitimat',
  'V8G - Terrace',
  'V8J - Prince Rupert',
  'V8K - Salt Spring Island',
  'V8L - Sidney',
  'V8M - Central Saanich',
  'V8N - Victoria',
  'V8P - Victoria',
  'V8R - Victoria',
  'V8S - Victoria',
  'V8T - Victoria',
  'V8V - Victoria',
  'V8W - Victoria',
  'V8X - Victoria',
  'V8Y - Victoria',
  'V8Z - Victoria',
  'V9A - Victoria',
  'V9B - Victoria',
  'V9C - Victoria',
  'V9E - Victoria',
  'V9G - Ladysmith',
  'V9H - Campbell River',
  'V9J - Courtnay',
  'V9K - Qualicum Beach',
  'V9L - Duncan',
  'V9M - Comox',
  'V9N - Courtnay',
  'V9P - Parksville',
  'V9R - Nanaimo',
  'V9S - Nanaimo',
  'V9T - Nanaimo',
  'V9V - Nanaimo',
  'V9W - Campbell River',
  'V9X - Nanaimo',
  'V9Y - Port Alberni',
  'V9Z - Sooke',
];

export default postalCodes;
