import React from 'react';
import { PolicyDiv } from '../../assets/styledComponents/policyDiv';

const PrivacyPolicyText = () => {
  return (
    <PolicyDiv>
      <h3>SOCIAL MEDIA MODERATION POLICY</h3>
      <p>
        My Pandemic Story encourages candid dialogue and invites you to
        contribute to discussions on our social media pages. If you choose to
        engage with our content, we ask that you do so in a respectful and
        constructive manner. To ensure our social media pages remain welcoming
        and productive spaces, we reserve the right to remove/omit content that
        abuses the following conditions:
      </p>
      <ul>
        <li>
          Includes discriminatory, hateful, racist, sexist, homophobic,
          transphobic, or threatening remarks
        </li>
        <li>Contains inflammatory, obscene, abusive, or offensive language</li>
        <li>Deviates from the flow of polite discourse or are far-off topic</li>
        <li>References self-harm or harm of a third party</li>
        <li>Jeopardizes the safety of anyone</li>
        <li>
          Encourages conduct that may or would violate the laws of relevant
          jurisdictions
        </li>
        <li>Invades anyone’s privacy or impersonates anyone</li>
        <li>Spreads unproven, unsupported, or factually inaccurate claims</li>
        <li>
          Makes unsupported or unproven accusations against individuals or
          organizations
        </li>
        <li>Campaigns for political parties or candidates</li>
        <li>Are posted without the necessary rights, licenses, and consent</li>
        <li>Contains excessive links, code, or long embedded URLs</li>
        <li>Constitutes spam, promotes services or products</li>
        <li>
          Are contrary to the principles of the Canadian Charter of Rights and
          Freedoms
        </li>
      </ul>
      <p>
        We retain the right to block a person from further activity on our pages
        for violations of the above conditions. If you have questions/concerns
        about this moderation policy and how we apply it, please contact:{' '}
        <a target="_blank" rel="noreferrer" href="mailto:timeline@bcchr.ca ">
          timeline@bcchr.ca{' '}
        </a>
      </p>
      <br />
      <h4>
        <strong>Hashtag Usage</strong>
      </h4>
      <p>
        If you intend to use our hashtag, <strong>#MyBCCovidStories</strong>,
        please ensure you are adhering to these conditions. While we are able to
        monitor the content we share, we are not responsible for the content of
        posts that use this hashtag.
      </p>
      <ul>
        <li>
          <strong>BC Gov </strong>
          {' - '}
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www2.gov.bc.ca/gov/content/governments/services-for-government/policies-procedures/social-media-guidelines/social-media-social-media-moderation-policy"
          >
            Sample Moderation Policy
          </a>
        </li>
        <li>
          <strong> BC Government </strong>
          {' - '}
          <a
            target="_blank"
            rel="noreferrer"
            href="https://news.gov.bc.ca/stories/bc-government-news-social-media-moderation-policy-hours-of-operation-and-collection-notice"
          >
            Social Media Moderation Policy
          </a>
        </li>
        <li>
          <strong>SFU</strong>
          {' - '}
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.sfu.ca/contact/terms-conditions/social-media-moderation-policy.html"
          >
            Social Media Moderation Policy
          </a>
        </li>
        <li>
          <strong>UBC</strong>
          {' - '}
          <a
            target="_blank"
            rel="noreferrer"
            href="http://assets.brand.ubc.ca/downloads/ubc_social_moderation_and_response.pdf
https://www.dropbox.com/s/94iau059n2kvgyl/ubc_social_media_moderation_guidelines.doc?dl=0
"
          >
            Moderation Guidelines
          </a>
        </li>
        <li>
          <strong>UBC</strong>
          {' - '}
          <a
            target="_blank"
            rel="noreferrer"
            href="http://assets.brand.ubc.ca/downloads/ubc_social_media_best_practices.pdf"
          >
            Social Media Best Practices
          </a>
        </li>
        <li>
          <strong> Alberta Government </strong>
          {' - '}
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.alberta.ca/social-media-commenting-rules.aspx"
          >
            Social Media Moderation Policy{' '}
          </a>
        </li>
      </ul>
      <br />

      <p>
        Collection, use, disclosure and retention of personal information is
        subject to, and must comply with, the provisions of the{' '}
        <a
          target="_blank"
          rel="noreferrer"
          href="http://www.bclaws.ca/EPLibraries/bclaws_new/document/ID/freeside/96165_01"
        >
          BC Freedom of Information and Protection of Privacy Act
        </a>
        (FIPPA).
      </p>
      <br />
      <p>
        The purpose of this privacy notice is to describe our practices
        regarding personal information use and collection through My Pandemic
        Stories. Please note that we do not disclose, give, sell or transfer any
        personal information about our website visitors unless required for law
        enforcement or otherwise provided for by law.
      </p>
      <br />
      <p>
        We reserve the right to update this policy at any time. Users are
        responsible for reviewing this document periodically for changes.
      </p>
      <br />
      <h3>What is personal information?</h3>
      <p>
        Personal information is recorded information about an identifiable
        individual other than business contact information and would include,
        but is not limited to, such things as an individual&apos;s name,
        address, birth date, email address, and phone number.
      </p>
      <br />
      <h4>
        <strong>Collection &amp; use of information</strong>
      </h4>
      <p>
        My Pandemic Story is authorized to collect personal information via this
        website under section 26 (c) of FIPPA for the purposes described below.
        Information is collected from our websites, either directly from you or
        automatically.
      </p>
      <br />
      <p>
        <strong>Information collected directly from you</strong>
      </p>
      <br />
      <p>
        Personal information is collected through our websites in certain
        circumstances, such as when you send an email message to us or complete
        a questionnaire, form, or other online survey found on our websites.
      </p>
      <br />
      <p>
        We only use the personal information you provide to us through our
        websites for purposes consistent with its collection.
      </p>
      <br />
      <p>
        You should also be aware that information provided by you becomes a
        record of the organization and is subject to the access and privacy
        protection requirements set out in FIPPA.
      </p>
      <br />
      <p>
        This information may be further collected, used and disclosed in
        accordance with that Act or as otherwise required by law. Under this
        legislation you are also entitled to access and correct any of your
        personal information that is within our custody and control. You may
        make such requests by contacting the privacy representative listed on
        this page.
      </p>
      <br />
      <p>
        <strong>Information collected automatically</strong>
      </p>
      <br />
      <p>
        Some of our web pages use &quot;cookies,&quot; which are small amounts
        of information stored by your web browser software on your work station.
      </p>
      <br />
      <p>
        Cookies are designed to track browsing history on a website generally
        for the convenience of the user. They help websites &quot;remember&quot;
        what areas of a website a browser has accessed or used previously.
        Cookies can, however, be misused, and have become a privacy concern for
        some because they can be used to record and analyze an individual&apos;s
        broader browsing history.
      </p>
      <br />
      <p>
        The cookies we use on our websites and what we use them for are
        described below.
      </p>
      <br />
      <p>
        This site operates on &quot;implied consent,&quot; which means that by
        visiting and clicking through the site, we assume you agree with this
        usage. If you do not agree, then you should not use our sites, or you
        can delete the cookies after visiting our sites, or use your
        browser&apos;s anonymous usage setting (called &quot;Incognito&quot; in
        Chrome, &quot;In Private&quot; in Internet Explorer, &quot;Private
        Browsing&quot; in Firefox and Safari).
      </p>
      <br />
      <p>
        More information on cookies, what they are used for and how to delete
        cookies after visiting this site can be found at{' '}
        <a
          target="_blank"
          rel="noreferrer"
          href="http://www.allaboutcookies.org/cookies/session-cookies-used-for.html"
        >
          http://www.allaboutcookies.org/cookies/session-cookies-used-for.html
        </a>
        .
      </p>
      <br />
      <p>
        It&apos;s important to know that cookies are plain text files and cannot
        execute files or make copies of themselves. They do not carry or deliver
        viruses and cannot scan or retrieve your personal information.
      </p>
      <br />
      <p>
        There are two types of cookie &ndash; <strong>Session</strong> and{' '}
        <strong>Persistent</strong>.
      </p>
      <br />
      <p>
        <strong>Session cookies</strong>
      </p>
      <br />
      <p>
        Session cookie information is deleted as soon as you leave our websites.
        The use of temporary session cookies is solely for the purpose of
        allowing the user to interact with our websites in an effective manner.
      </p>
      <br />
      <p>
        <strong>Persistent cookies</strong>
      </p>
      <br />
      <p>
        In a few cases our website may use persistent cookies to store
        information for longer periods than the current session.
      </p>
      <br />
      <h4>
        <strong>Analytics &amp; safeguards</strong>
      </h4>
      <p>
        <strong>Analytics</strong>
      </p>
      <br />
      <p>
        My Pandemic Story uses Google Analytics, a web analytics service
        provided by Google, Inc. (Google).
      </p>
      <br />
      <p>
        Google Analytics uses first-party cookies to track visitor interactions.
        The information generated by the cookie about your use of this website
        (including your IP address) will be transmitted to and stored by Google
        on servers in the United States. Google will use this information for
        the purpose of evaluating your use of this website, compiling reports on
        website activity for the website operators and providing other services
        relating to website activity and internet usage. Google may also
        transfer this information to third parties where required to do so by
        law or where such third parties process the information on Google&apos;s
        behalf.
      </p>
      <br />
      <p>
        You may refuse the use of cookies by selecting the appropriate settings
        on your browser - however please note that if you do this you may not be
        able to use the full functionality of this website.
      </p>
      <br />
      <p>
        My Pandemic Story uses IP masking within Google Analytics to anonymize
        personal information collected by Google. IP masking is a customization
        which removes the last octet of the visitor&apos;s IP address prior to
        its use and storage by Google.
      </p>
      <br />
      <p>
        Website visitors who do not want to be tracked by Google Analytics can
        download the Google Analytics Opt-out Browser Add-on. The add-on
        communicates with the Google Analytics JavaScript (ga.js) to indicate
        that information about the website visit should not be sent to Google
        Analytics. The Google Analytics Opt-out Browser Add-on does not prevent
        information from being sent to the website itself or to other web
        analytics services.
      </p>
      <br />
      <p>
        For more information visit the{' '}
        <a
          target="_blank"
          rel="noreferrer"
          href="http://www.google.com/analytics/standard/"
        >
          Google Analytics Overview
        </a>{' '}
        page.
      </p>
      <br />
      <p>
        <strong>Safeguards</strong>
      </p>
      <br />
      <p>
        My Pandemic Story is obligated to protect your personal information by
        making reasonable security arrangements against such risks as
        unauthorized access, collection, use, disclosure, or disposal. Security
        measures have been integrated into the design, implementation, and
        day-to-day operating practices as part of My Pandemic Story&apos;s
        continuing commitment to the protection of personal information it
        holds.
      </p>
      <br />
      <h3>Email</h3>
      <p>
        Information sent to our websites over the Internet is not secure. This
        means that if the visitor uses the Contact Us page, or other email
        addresses published on our websites, to send an email, the information
        provided may be intercepted as it travels to our websites. We will not
        be responsible for any damages suffered by individuals as a result of
        the transmission and information that is sent to us or for any errors or
        changes made to transmitted information.
      </p>
      <br />
      <h3>Social media buttons</h3>
      <p>
        You might see &quot;social media buttons&quot; on some My Pandemic Story
        websites.
      </p>
      <br />
      <p>
        Social media buttons let visitors share or bookmark web pages, and are
        often identifiable by the + sign with Share beside it. There may be
        buttons for: Twitter, Facebook &apos;Like&apos;, LinkedIn
        &apos;Share&apos;, and others.
      </p>
      <br />
      <p>
        In order to connect social buttons to the relevant external sites, there
        are scripts from those domains, which are outside of this site. Those
        sites could be collecting information about what you are doing all
        around the internet, including this site. To be certain what information
        those sites collect, and how to opt out, check the respective policies
        of each site.
      </p>
      <br />
      <p>
        <strong>Related Links</strong>
      </p>
      <br />
      <p>
        My Pandemic Story websites contains links to other websites. My Pandemic
        Story is not responsible for the privacy practices of these websites or
        the accuracy or reliability of any content found on such sites.
      </p>
      <br />
      <h3>Data Retention Policy</h3>
      <p>
        My Pandemic Story reains your information to keep track on your stories
        in our database, including:
      </p>
      <ul>
        <li>Story picture</li>
        <li>Story video</li>
        <li>Story title</li>
        <li>Name</li>
        <li>Date</li>
        <li>City</li>
        <li>Your Story</li>
      </ul>
    </PolicyDiv>
  );
};

export default PrivacyPolicyText;
