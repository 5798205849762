import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import React from 'react';
import colors from '../../../assets/styles/colors';

const borealisBar = keyframes`
0% {
  left:0%;
  right:100%;
  width:0%;
}
10% {
  left:0%;
  right:75%;
  width:25%;
}
90% {
  right:0%;
  left:75%;
  width:25%;
}
100% {
  left:100%;
  right:0%;
  width:0%;
}
`;

const ImageLoader = () => {
  return (
    <Container>
      <div className="loader">
        <div className="loaderBar"></div>
      </div>
    </Container>
  );
};

const Container = styled.div`
  position: absolute;
  width: 110px;
  height: 10px;
  background-color: ${colors.white};
  bottom: 0;
  .loader {
    width: 110px;
    height: 10px;
    margin: 0 auto;
    position: relative;
    border: 1px solod red;
  }
  .loader:before {
    content: '';
    position: absolute;
  }
  .loader .loaderBar {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    background: ${colors.bluegreen};
    width: 0;
    animation: ${borealisBar} 2s linear infinite;
  }
`;

export default ImageLoader;
