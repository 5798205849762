const ResourcesCategories = Object.freeze({
  ALL: 'All',
  COVID_19_INFORMATION: 'COVID-19 Information',
  MENTAL_HEALTH: 'Mental Health',
  ADVOCACY_AWARENESS: 'Advocacy & Awareness',
  HEALTH_RESOURCES: 'Health Resources',
  CHILDREN_YOUTH_FAMILY: 'Children, Youth, Family',
});

export default ResourcesCategories;
