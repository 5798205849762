import styled from '@emotion/styled';
import React from 'react';
import CloseModalButton from '../../assets/styledComponents/CloseModalButton';
import ImageSlider from '../../assets/styledComponents/ImageSlider';
import ModalOverlay from '../../assets/styledComponents/ModalOverlay';
import colors from '../../assets/styles/colors';
import ExpandedStoryCard from '../StoriesPage/ExpandedStoryCard';
import StoryTypes from '../../types/StoryTypes';

const ModalCard = ({
  setShowModal,
  showModal,
  storyMediaContent,
  isStoryPage,
  date_stamp,
  title,
  description,
  name,
  postalCode,
}) => {
  const postWithMedia = isStoryPage
    ? (storyMediaContent.type === StoryTypes.SOCIAL_MEDIA_POST &&
        storyMediaContent.media.image !== '') ||
      storyMediaContent.media.video
    : true;

  const storyWithMedia = isStoryPage
    ? storyMediaContent.type === StoryTypes.STORY &&
      storyMediaContent.media.length > 0
    : true;

  const cardWithMedia = postWithMedia || storyWithMedia;

  return (
    <div>
      <ModalOverlay setShowModal={setShowModal} showModal={showModal} />
      <Container cardWithMedia={cardWithMedia}>
        <CloseModalButton
          setShowModal={setShowModal}
          isStoryPage={isStoryPage}
        />
        {isStoryPage ? (
          <ExpandedStoryCard
            mediaContent={storyMediaContent}
            date_stamp={date_stamp}
            title={title}
            description={description}
            name={name}
            postalCode={postalCode}
            isStoryPage={isStoryPage}
            cardWithMedia={cardWithMedia}
          />
        ) : (
          <ImageSlider
            mediaContent={storyMediaContent}
            width="1160px"
            height="720px"
            attributeMargin="3rem"
            dotsPadding="3rem"
            isExpandedImage={true}
          />
        )}
      </Container>
    </div>
  );
};

const Container = styled.div`
  width: ${(props) => (props.cardWithMedia ? '1160px' : '360px')};
  max-width: 100vw;
  height: 720px;
  max-height: 100vh;
  background-color: ${colors.almostBlack};
  border-radius: 1rem;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
  overflow: hidden;
`;

export default ModalCard;
