/*eslint-disable*/
import styled from '@emotion/styled';
import closeIcon from 'assets/images/icon_close.svg';
import colors from 'assets/styles/colors';
import React from 'react';
import CurrentRefinements from './CurrentRefinements';
import breakPoints from 'assets/styles/breakpoints';

export default function SearchFilterPillBar({ searchTerm, setSearchTerm }) {
  const handleClearSearch = () => {
    setSearchTerm('');
  };

  return (
    <PillBarWrapper>
      {searchTerm && (
        <SearchResultWrapper>
          <ResultText>
            Search Result for <span>{searchTerm}</span>
          </ResultText>
          <ClearSearchButton onClick={handleClearSearch} />
        </SearchResultWrapper>
      )}
      <FilterPills>
        <CurrentRefinements />
      </FilterPills>
    </PillBarWrapper>
  );
}

const PillBarWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1160px;
  margin: 0 auto;
  margin-bottom: 40px;

  @media only screen and (max-width: ${breakPoints.mobile}) {
    margin-bottom: 20px;
  }
`;

const SearchResultWrapper = styled.div`
  display: flex;
`;

const ClearSearchButton = styled.button`
  margin-left: 5px;
  width: 16px;
  height: 16px;
  border-radius: 20px;
  border: none;
  background-color: ${colors.lightGrey};
  padding: 0;
  display: flex;
  cursor: pointer;
  background-image: url(${closeIcon});
  background-size: 16px 16px;
  transform: translateY(2px);
`;

const ResultText = styled.p`
  font-family: 'Khula-SemiBold', sans-serif;
  margin-bottom: 5px;
  span {
    font-family: 'Khula-Bold', sans-serif;
  }
`;

const FilterPills = styled.div`
  display: flex;
  flex-wrap: wrap;
`;
