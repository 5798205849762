import styled from '@emotion/styled';
import dateFormat from 'dateformat';
import { DateHeader5 } from '../../../assets/styledComponents/DateHeader5';
import { TitleHeader4 } from '../../../assets/styledComponents/TitleHeader4';
import React, { useCallback, useState } from 'react';
import { Body4 } from 'assets/styledComponents/Body4';
import { Body3 } from 'assets/styledComponents/Body3';
import colors from 'assets/styles/colors';

const StoryContent = ({
  date_stamp,
  title,
  description,
  name,
  postalCode,
  handleShowExpandedCard,
}) => {
  const city = postalCode && postalCode.substring(5, postalCode.length);
  const [isExpanded, setIsExpanded] = useState(false);
  const [descriptionLinesCount, descriptionRef] = useElementLinesCount();
  const descriptionLineLimit = 6;
  const characterLimit = 215;

  function useElementLinesCount() {
    const [count, setCount] = useState(null);
    const ref = useCallback((node) => {
      if (node !== null) {
        setCount(countLinesInElement(node));
      }
    }, []);
    return [count, ref];
  }

  const countLinesInElement = (el) => {
    let divHeight = el.offsetHeight;
    let lineHeight = 25; // line height for card description
    return Math.round(divHeight / lineHeight);
  };

  return (
    <StoryContentWrapper>
      <UpperWrapper>
        <HandleClickWrapper onClick={handleShowExpandedCard}>
          <DateHeader5>{dateFormat(date_stamp, 'yyyy.mm.dd')}</DateHeader5>
          <TitleMouseOverWrapper>
            <TitleHeader4>{title}</TitleHeader4>
          </TitleMouseOverWrapper>
          <StoryParagraph
            descriptionLinesCount={descriptionLinesCount}
            isExpanded={isExpanded}
          ></StoryParagraph>
        </HandleClickWrapper>
        <Body3 ref={descriptionRef}>
          {isExpanded
            ? description
            : description.length > characterLimit
            ? `${description.substring(0, characterLimit)}...`
            : description}
          {descriptionLinesCount === descriptionLineLimit ? (
            <Button onClick={() => setIsExpanded(!isExpanded)}>
              <Body4 color={colors.bluegreen}>
                {isExpanded ? 'Less' : 'More'}
              </Body4>
            </Button>
          ) : (
            ''
          )}
        </Body3>
      </UpperWrapper>
      <NameAndCity>{`${name}${name && city ? ',' : ''}${city}`}</NameAndCity>
    </StoryContentWrapper>
  );
};

const StoryContentWrapper = styled.div`
  padding: 30px;
  text-align: left;
  min-height: 351px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const StoryParagraph = styled.p`
  font-family: Khula-Regular;
  color: #0f374a !important;
  font-size: 16px !important;
  line-height: 25px !important;
`;

const NameAndCity = styled.p`
  font-family: Mulish-Regular;
  color: #0f374a !important;
  font-size: 12.5px !important;
  line-height: 17px !important;
  margin-top: 20px !important;
  margin-bottom: 10px !important;
  text-align: right;
`;
const Button = styled.button`
  background: none;
  border: none;
  text-decoration: underline;
  color: #00c2ba !important;
  :hover {
    cursor: pointer;
  }
`;

const TitleMouseOverWrapper = styled.div`
  :hover {
    cursor: pointer;
  }
`;

const HandleClickWrapper = styled.div``;

const UpperWrapper = styled.div``;

export default StoryContent;
