import styled from '@emotion/styled';
import React from 'react';
import close_grey from '../../assets/images/close-grey.svg';
import close from '../../assets/images/close.svg';

const CloseModalButton = ({ setShowModal, isStoryPage }) => {
  return (
    <StyledButton
      isStoryPage={isStoryPage}
      onClick={() => {
        setShowModal(false);
      }}
    >
      <img
        src={isStoryPage ? close_grey : close}
        alt="x-shaped close modal icon"
      />
    </StyledButton>
  );
};

const StyledButton = styled.button`
  background-color: transparent;
  position: absolute;
  top: 0;
  right: 0;
  border: none;
  padding: 20px;
  z-index: 15;
  :hover {
    cursor: pointer;
    opacity: 0.4;
  }
`;

export default CloseModalButton;
