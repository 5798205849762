import styled from '@emotion/styled';
import React from 'react';
import videoPlaceHolder from '../../../assets/images/video_placeholder_sm.svg';
import colors from '../../../assets/styles/colors';
import ImageLoader from './ImageLoader';
import breakPoints from 'assets/styles/breakpoints';

const MediaPreview = ({ data }) => {
  return (
    <Thumbnail>
      {data?.type && (
        <img
          src={data?.type === 'image' ? data?.thumbnailUrl : videoPlaceHolder}
          alt="preview"
        />
      )}
      {data?.progress > 0 && (
        <VideoLoadingProgressBar max="100" value={data?.progress} />
      )}
      {data?.imageLoading && <ImageLoader />}
    </Thumbnail>
  );
};

const Thumbnail = styled.div`
  background: ${colors.lightGrey};
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 110px;
  height: 110px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  text-align: center;

  img {
    width: 100%;
    height: 100%;
    margin: auto;
    display: block;
  }

  @media only screen and (max-width: ${breakPoints.tablet}) {
    width: 40vw;
    height: 40vw;
    min-width: 150px;
    min-height: 150px;
  }
`;

const VideoLoadingProgressBar = styled.progress`
width: 110px;
background-color: ${colors.white};

&[value] {
  /* Reset the default appearance */
  -webkit-appearance: none;
  appearance: none;

  position: absolute;
  width: 110px;
  height: 10px;
  bottom: 0;
  display: ${(props) =>
    props.value === '100.00' || props.value === 0 ? 'none' : 'block'}};
}

&[value]::-webkit-progress-value {
  background-color: ${colors.bluegreen};
}

/* Style progress container */
/* Chrome/Safari/Opera */
&[value]::-webkit-progress-bar {
  background-color: ${colors.white};
  width: 110px;
  height: 10px;
  box-shadow: 1px 1px 10px 3px rgba(0,0,0,0.20);
}

/* Firefox */
&[value]::-moz-progress-bar {
  background-color: ${colors.bluegreen};
  padding: 0 15px 0 15px;
  box-shadow: 1px 1px 10px 3px rgba(0,0,0,0.20);
}
`;

export default MediaPreview;
