const colors = {
  white: `#ffffff`,
  lightGrey: `#EBEEF0`,
  grey: `#A0A8AC`,
  darkGrey: `#6D767C`,
  darkNavy: `#0F374A`,
  almostBlack: `#121212`,
  lightBlue: `#E2F5FE`,
  lightBluegreen: `#ABDDDF`,
  bluegreen: `#00C2BA`,
  lightYellow: `#FFDD85`,
  darkYellow: `#FAB550`,
  webLink: `#007EF2`,
};
export default colors;
