import React from 'react';
import styled from '@emotion/styled';
import quotationMark from '../../assets/images/quotationMark.svg';
import breakPoints from 'assets/styles/breakpoints';
import ScrollHelper from 'assets/styledComponents/ScrollHelper';

const Testimonial = (props) => {
  const { title, description } = props;

  return (
    <>
      <Wrapper>
        <Quote>
          <img src={quotationMark} alt="quotation mark"></img>
          <div id="text">{title}</div>
          <img id="flipped" src={quotationMark} alt="quotation mark"></img>
        </Quote>
        <Copy dangerouslySetInnerHTML={{ __html: description }}></Copy>
      </Wrapper>
      <ScrollHelper></ScrollHelper>
    </>
  );
};

const Wrapper = styled.div`
  background-color: #00c2ba;
  height: 353px;
  display: flex;
  align-items: center;
  padding: 0px 90px;
  @media only screen and (max-width: 1024px) {
    display: block;
    padding: 70px 70px;
    text-align: center;
  }

  @media only screen and (max-width: ${breakPoints.tablet}) {
    height: auto;
    padding: 65px 20px;
  }
`;

const Quote = styled.div`
  font-family: 'Khula-Regular';
  font-weight: 600;
  font-size: 29px;
  line-height: 45px;
  width: 50%;
  color: white;
  letter-spacing: 0.3px;
  display: flex;
  align-items: flex-start;
  position: relative;
  text-align: left;
  padding: 0px 50px 0px 25px;
  #text {
    margin: 0px 10px;
    z-index: 10;

    @media only screen and (max-width: ${breakPoints.tablet}) {
      margin: 0;
    }
  }

  #flipped {
    transform: scaleX(-1);
  }

  @media only screen and (max-width: 1024px) {
    width: 100%;
    margin-bottom: 30px;
  }

  @media only screen and (max-width: ${breakPoints.tablet}) {
    width: auto;
    height: auto;
    padding: 0px 15px;
    text-align: center;
    font-size: 24px;
    line-height: 35px;
    font-weight: 400;
  }
`;

const Copy = styled.div`
  width: 40%;
  p {
    font-family: 'Mulish-Regular';
    color: #121212;
    line-height: 26px;
    font-weight: 600;
    font-size: 14px;
  }
  @media only screen and (max-width: 1024px) {
    width: 100%;
    padding-bottom: 80px;
  }
`;
export default Testimonial;
