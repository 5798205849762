import styled from '@emotion/styled';
import React from 'react';
import StoryTypes from 'types/StoryTypes';

const Video = ({ type, src }) => {
  const videoLink =
    type === StoryTypes.SOCIAL_MEDIA_POST
      ? src
      : `${src}&title=0&byline=0&portrait=0`;

  return (
    <Container>
      <Iframe
        title="uniquestuff"
        src={videoLink}
        allow="autoplay; picture-in-picture"
        frameBorder="0"
      ></Iframe>
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

const Iframe = styled.iframe`
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border: none;
`;

export default Video;
