import styled from '@emotion/styled';
import dateFormat from 'dateformat';
import React, { useEffect, useRef, useState, useContext } from 'react';
import { GlobalContext } from 'contexts/global/GlobalContext';
import { VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import { ReactComponent as Ellipse } from '../../assets/images/ellipse.svg';
import CardImageSection from './CardImageSection';
import breakPoints from 'assets/styles/breakpoints';
import { ReactComponent as MinusIcon } from '../../assets/images/minus-icon.svg';
import { ReactComponent as PlusIcon } from '../../assets/images/plus-icon.svg';

const TimelineElement = ({ card, id }) => {
  const [height, setHeight] = useState(0);
  const textRef = useRef(0);
  const imageRef = useRef(0);
  const [{ isMobile }] = useContext(GlobalContext);
  const [isExpanded, setIsExpanded] = useState(false);

  useEffect(() => {
    let textHeight = textRef.current.clientHeight;
    let imageHeight = imageRef.current.clientHeight ? 355 : 0;
    setHeight(textHeight + imageHeight);
  }, [textRef, imageRef]);

  const formatBulletPoints = (text) => {
    const paragraphs = text.split('</p>');

    for (let i = 1; i < paragraphs.length; i++) {
      if (paragraphs[i].includes('●&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;')) {
        paragraphs[i] =
          paragraphs[i].replace('●&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;', '<ul><li>') +
          '</li></ul>';
      }
    }

    return paragraphs.join('</p>');
  };

  const renderElementHeaders = () => {
    if (isMobile) {
      return (
        <>
          <TitleWrapper>
            <DateTitleWrapper>
              <h5 id="date">
                {dateFormat(card.date_stamp, 'yyyy. mmmm').toUpperCase()}
              </h5>
              <h4>{card.title}</h4>
            </DateTitleWrapper>
            <IconWrapper>
              {isExpanded ? (
                <MinusIconButton
                  onClick={() => {
                    setIsExpanded(!isExpanded);
                  }}
                ></MinusIconButton>
              ) : (
                <PlusIconButton
                  onClick={() => {
                    setIsExpanded(!isExpanded);
                  }}
                ></PlusIconButton>
              )}
            </IconWrapper>
          </TitleWrapper>
        </>
      );
    }

    return (
      <>
        <TitleWrapper>
          <DateTitleWrapper>
            <h4 id="date">
              {dateFormat(card.date_stamp, 'yyyy. mmmm').toUpperCase()}
            </h4>
            <h3>{card.title}</h3>
          </DateTitleWrapper>
          <IconWrapper>
            {isExpanded ? (
              <MinusIconButton
                onClick={() => {
                  setIsExpanded(!isExpanded);
                }}
              ></MinusIconButton>
            ) : (
              <PlusIconButton
                onClick={() => {
                  setIsExpanded(!isExpanded);
                }}
              ></PlusIconButton>
            )}
          </IconWrapper>
        </TitleWrapper>
      </>
    );
  };

  return (
    <TimelineElementContainer
      elementHeight={height}
      key={id}
      id={id}
      contentStyle={{
        background: '#E2F5FE',
        color: '#0F374A',
        boxShadow: '0px 50px 60px rgba(0, 0, 0, 0.5)',
        padding: '0px',
      }}
      contentArrowStyle={{
        borderRight: '30px solid #E2F5FE',
      }}
      iconStyle={{
        background:
          'radial-gradient(rgba(255,255,255,0) 0%, rgba(15,55,74,0) 10%, #b7c3c9 91.15%)',
        width: '68px',
        height: '68px',
      }}
      icon={<Ellipse />}
    >
      <Text ref={textRef}>
        {renderElementHeaders()}
        {isExpanded ? (
          <p
            dangerouslySetInnerHTML={{
              __html: formatBulletPoints(card.description),
            }}
          ></p>
        ) : (
          <p></p>
        )}
      </Text>
      <div ref={imageRef}>
        {card.milestone_image && (
          <CardImageSection mediaContent={card.milestone_image} />
        )}
      </div>
    </TimelineElementContainer>
  );
};

const TimelineElementContainer = styled(VerticalTimelineElement)`
  margin-top: ${(props) => (props.elementHeight > 600 ? '-200px' : '-100px')};
  .vertical-timeline-element-content-arrow {
    border: 10px solid transparent;
  }

  .vertical-timeline-element-icon {
    box-shadow: none;
  }

  @media only screen and (max-width: 1169px) {
    margin-top: 50px;
    width: auto;
    .vertical-timeline-element-icon {
      width: 50px !important;
      height: 50px !important;
    }

    .vertical-timeline-element-date {
      display: none;
    }
  }
`;

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const DateTitleWrapper = styled.div``;

const MinusIconButton = styled(MinusIcon)`
  height: 20px;
  width: 20px;

  :hover {
    cursor: pointer;
  }
`;

const PlusIconButton = styled(PlusIcon)`
  height: 20px !important;
  width: 20px !important;

  :hover {
    cursor: pointer;
  }
`;

const IconWrapper = styled.div`
  display: flex;
  margin-left: 20px;
`;

const Text = styled.div`
  padding: 50px;

  h3 {
    color: #121212;
  }

  h4 {
    color: #00c2ba;
  }

  a {
    text-decoration: none;
    color: #007ef2 !important;
  }

  ul {
    padding-left: 1.5em;

    li {
      padding-left: 1em;
      ::marker {
        color: #00c2ba;
        font-size: 1.5em;
      }
    }
  }

  p {
    font-family: Khula-SemiBold;
  }

  @media only screen and (max-width: ${breakPoints.tablet}) {
    padding: 20px;

    p {
      font-family: Mulish-Regular;
      font-size: 12.5px;
      font-weight: 600;
      line-height: 17px;
      letter-spacing: 0.35px;
    }

    h4 {
      color: #121212;
    }

    h5 {
      color: #00c2ba;
    }
  }
`;

export default TimelineElement;
