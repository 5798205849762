import React, { useContext } from 'react';
import { useQuery } from '@apollo/client';
import styled from '@emotion/styled';
import { GlobalContext } from 'contexts/global/GlobalContext';
import { Body3 } from '../../assets/styledComponents/Body3';
import { Body3Bold } from '../../assets/styledComponents/Body3Bold';
import { PageContainer } from '../../assets/styledComponents/PageContainer';
import TitleHeader2WithDots from '../../assets/styledComponents/TitleHeader2WithDots';
import breakPoints from '../../assets/styles/breakpoints';
import colors from '../../assets/styles/colors';
import { GET_CONTACT_DATA } from '../../graphql/queries';
import Map from './Map';
import Header3 from 'assets/styledComponents/Header3';

const ContactUsPage = () => {
  const { data: contactData } = useQuery(GET_CONTACT_DATA);
  const [{ isMobile }] = useContext(GlobalContext);

  const contact = [
    {
      title: 'Email:',
      data: contactData && contactData.footer.email,
      color: colors.webLink,
    },
    {
      title: 'Address:',
      data: contactData && contactData.footer.address,
      color: colors.almostBlack,
    },
  ];

  return (
    <PageContainer>
      <ContactPageContainer>
        {isMobile ? (
          <Header3 color={colors.almostBlack} title="Contact Us" />
        ) : (
          <TitleHeader2WithDots color={colors.almostBlack} title="Contact Us" />
        )}
        <Body3 color={colors.almostBlack}>
          Contact us if you have any inquiries.
        </Body3>
        <ContactInfoSection>
          <VerticalLine />
          <ul>
            {contact.map((item) => (
              <ContactInfo key={item.title}>
                <Body3Bold>{item.title}</Body3Bold>
                <InfoText color={item.color}>{item.data}</InfoText>
              </ContactInfo>
            ))}
          </ul>
        </ContactInfoSection>
        <Map />
      </ContactPageContainer>
    </PageContainer>
  );
};

const ContactPageContainer = styled.div`
  max-width: 1160px;
  margin: auto;

  @media only screen and (max-width: ${breakPoints.tablet}) {
    width: auto;
  }
`;

const ContactInfoSection = styled.div`
  display: flex;
  margin-top: 20px;

  @media only screen and (max-width: ${breakPoints.mobile}) {
    margin-top: 30px;
  }
`;

const VerticalLine = styled.div`
  border-left: 7px solid ${colors.lightBlue};
  border-radius: 4px;
`;

const ContactInfo = styled.div`
  display: flex;
  margin-left: 20px;
`;

const InfoText = styled(Body3)`
  margin-left: 3px;
`;

export default ContactUsPage;
