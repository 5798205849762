import React, { useState, useContext } from 'react';
import styled from '@emotion/styled';
import colors from '../assets/styles/colors';
import { GlobalContext } from 'contexts/global/GlobalContext';
import { useQuery } from '@apollo/client';
import { GET_LANDING_PAGE } from '../graphql/queries';
import ShareYourStoryCard from '../components/landing_components/ShareYourStoryCard';
import Section from '../components/Section';
import CardContainer from '../components/CardContainer';
import TitleHeader2WithDots from '../assets/styledComponents/TitleHeader2WithDots';
import TitleHeader3WithDots from '../assets/styledComponents/TitleHeader3WithDots';
import HeroCard from '../components/landing_components/HeroCard';
import Testimonial from '../components/landing_components/Testimonial';
import Timeline from '../components/landing_components/Timeline';
import breakPoints from 'assets/styles/breakpoints';
import LoadingSpinner from 'assets/styledComponents/LoadingSpinner';

const LandingPage = () => {
  const [yearArray, setYearArray] = useState([]);
  const [{ isMobile }] = useContext(GlobalContext);

  const {
    loading: landingPageLoading,
    error: landingPageError,
    data: landingPageData,
  } = useQuery(GET_LANDING_PAGE);

  if (landingPageLoading) {
    return <LoadingSpinner />;
  }
  if (landingPageError) {
    return <p>error</p>;
  }

  const {
    hero_section,
    testimonial_section,
    share_your_story,
    milestone,
    timeline_description,
  } = landingPageData.landingPage;

  const renderStoryCards = () => {
    if (share_your_story.card) {
      return share_your_story.card.map((card, id) => (
        <ShareYourStoryCard {...card} key={id} />
      ));
    }
  };

  const renderSubHeader = () => {
    if (isMobile) {
      return (
        <TitleHeader3WithDots
          color={colors.almostBlack}
          title={share_your_story.title}
        />
      );
    }

    return (
      <TitleHeader2WithDots
        color={colors.almostBlack}
        title={share_your_story.title}
      />
    );
  };

  return (
    <div>
      <HeroCard {...hero_section} />
      <Testimonial {...testimonial_section} />
      <HeaderDiv>{renderSubHeader()}</HeaderDiv>
      <Section>
        <CardContainer columns="4" smallScreenColumns="2">
          {renderStoryCards()}
        </CardContainer>
      </Section>
      <Timeline
        description={timeline_description}
        milestones={milestone}
        yearArray={yearArray}
        setYearArray={setYearArray}
      />
    </div>
  );
};

const HeaderDiv = styled.div`
  margin-top: 120px;
  margin-left: 140px;
  margin-bottom: 60px;

  @media only screen and (max-width: ${breakPoints.tablet}) {
    margin: 0;
    padding: 55px 20px 0px;
  }
`;

export default LandingPage;
