import styled from '@emotion/styled';
import React, { useEffect, useState } from 'react';
import chevron from 'assets/images/icon_chevron.svg';
import colors from 'assets/styles/colors';

const ScrollToTopButton = () => {
  const [isVisible, setIsVisible] = useState(false);

  // Show button when page is scorlled upto given distance
  const toggleVisibility = () => {
    if (window.pageYOffset > 300) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
    });
  };

  useEffect(() => {
    let isMounted = true;
    window.addEventListener('scroll', () => isMounted && toggleVisibility());

    return () => {
      isMounted = false;
      window.removeEventListener('scroll', toggleVisibility);
    };
  }, []);

  return (
    <Container>
      {isVisible && (
        <Button onClick={scrollToTop}>
          <img src={chevron} alt="arrow up, scrolls the page to the top" />
          <p>top</p>
        </Button>
      )}
    </Container>
  );
};

const Container = styled.div`
  position: fixed;
  bottom: 50px;
  right: 50px;
  cursor: pointer;
  transition: visibility 1s linear;
`;

const Button = styled.button`
  background-color: ${colors.white};
  border: none;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.15);
  p {
    font-family: 'Mulish', sans-serif;
    font-weight: 800;
    font-size: 0.78125rem;
  }
  :hover {
    cursor: pointer;
  }
  :active {
    transform: translatey(2px);
  }
`;

export default ScrollToTopButton;
