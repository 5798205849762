import React, { useContext } from 'react';
import { GlobalContext } from 'contexts/global/GlobalContext';
import styled from '@emotion/styled';
import dotOrnament from '../../assets/images/dot-ornament.svg';
import breakPoints from 'assets/styles/breakpoints';

const Header = ({ title, color }) => {
  const [{ isMobile }] = useContext(GlobalContext);

  const renderText = () => {
    if (isMobile) {
      return <Header3 titleColor={color}>{title}</Header3>;
    }

    return <Header2 titleColor={color}>{title}</Header2>;
  };

  return (
    <Title>
      {renderText()}
      <img id="dot" src={dotOrnament} alt="dot ornament" />
    </Title>
  );
};

const Title = styled.div`
  margin: 120px 140px 60px;
  position: relative;
  #dot {
    width: 100px;
    position: absolute;
    left: -45px;
    bottom: -20px;
    z-index: -10;
  }
  @media only screen and (max-width: 1024px) {
    #dot {
      display: none;
    }
  }

  @media only screen and (max-width: ${breakPoints.tablet}) {
    margin: 0 0 25px;
    padding: 55px 20px 0px;
  }
`;

const Header2 = styled.h2`
  width: 35%;
  color: ${(props) => props.titleColor};
  &::before {
    position: absolute;
    width: 80px;
    content: ' ';
    border: 4px solid #00c2ba;
    background-color: #00c2ba;
    border-radius: 4px;
    top: -20px;
  }
`;

const Header3 = styled.h3`
  width: auto;
  color: ${(props) => props.titleColor};
  &::before {
    position: absolute;
    width: 50px;
    content: ' ';
    border: 4px solid #00c2ba;
    background-color: #00c2ba;
    border-radius: 4px;
    top: 35px;
  }
`;

export default Header;
