import { useQuery } from '@apollo/client';
import styled from '@emotion/styled';
import { React, useState, useContext } from 'react';
import { GlobalContext } from 'contexts/global/GlobalContext';
import { Body3 } from 'assets/styledComponents/Body3';
import TitleHeader2WithDots from 'assets/styledComponents/TitleHeader2WithDots';
import breakPoints from 'assets/styles/breakpoints';
import colors from 'assets/styles/colors';
import { GET_RESOURCES_PAGE } from 'graphql/queries';
import ResourcesCategories from 'types/ResourcesCategories';
import ScrollToTopButton from 'components/ScrollToTopButton';
import ResourceNavigation from './ResourceNavigation';
import CardSection from './CardSection';
import Header3 from 'assets/styledComponents/Header3';
import LoadingSpinner from 'assets/styledComponents/LoadingSpinner';

const ResourcesPage = () => {
  const [{ isMobile }] = useContext(GlobalContext);
  const {
    loading: resourcesPageLoading,
    data: resourcesPageData,
    error: resourcesPageError,
  } = useQuery(GET_RESOURCES_PAGE);

  const ALL_CATEGORIES = 'ALL';

  const [selectedCategory, setSelectedCategory] = useState(ALL_CATEGORIES);

  const handleCategoryChange = (category) => {
    setSelectedCategory(category);
  };

  return (
    <>
      {resourcesPageLoading ? (
        <LoadingSpinner />
      ) : (
        <>
          <ResourceHeader>
            <TitleContainer>
              {isMobile ? (
                <Header3
                  color={colors.almostBlack}
                  title={resourcesPageData.resourcesPage.title}
                />
              ) : (
                <TitleHeader2WithDots
                  color={colors.almostBlack}
                  title={resourcesPageData.resourcesPage.title}
                />
              )}
            </TitleContainer>
            <PageDescription>
              <Body3 color={colors.darkNavy}>
                {resourcesPageData.resourcesPage.description}
              </Body3>
            </PageDescription>
          </ResourceHeader>
          <ResourceNavigation
            resourcesCategories={Object.keys(ResourcesCategories)}
            handleCategoryChange={handleCategoryChange}
            selectedCategory={selectedCategory}
          />
          <CardSection
            resourcesCategories={Object.keys(ResourcesCategories)}
            selectedCategory={selectedCategory}
          />
          <ScrollToTopButton />
          {resourcesPageError ? <p>Error</p> : ''}
        </>
      )}
    </>
  );
};

const ResourceHeader = styled.div`
  padding: 140px 140px 0;

  @media only screen and (max-width: ${breakPoints.tablet}) {
    padding: 50px 20px 25px;
  }
`;

const TitleContainer = styled.div`
  max-width: 1160px;
  margin: 0 auto -20px;
`;

const PageDescription = styled.div`
  max-width: 1160px;
  margin: auto auto 80px auto;

  @media only screen and (max-width: ${breakPoints.tablet}) {
    width: auto;
    margin: 0;
  }
`;

export default ResourcesPage;
