import styled from '@emotion/styled';
import React, { useCallback, useState } from 'react';
import link from '../../assets/images/link.svg';
import { Body3 } from '../../assets/styledComponents/Body3';
import { Body4 } from '../../assets/styledComponents/Body4';
import { TitleHeader4 } from '../../assets/styledComponents/TitleHeader4';
import colors from '../../assets/styles/colors';
import getRandomColor from '../../utils/getRandomColor';
import getSubString from '../../utils/getSubString';

const ResourceCard = ({ resource }) => {
  const [descriptionLinesCount, descriptionRef] = useElementLinesCount();
  const [isExpanded, setIsExpanded] = useState(false);
  const descriptionLineLimit = 6;
  const characterLimit = 215;

  function useElementLinesCount() {
    const [count, setCount] = useState(null);
    const ref = useCallback((node) => {
      if (node !== null) {
        setCount(countLinesInElement(node));
      }
    }, []);
    return [count, ref];
  }

  const countLinesInElement = (el) => {
    let divHeight = el.offsetHeight;
    let lineHeight = 25; // line height for card description
    return Math.round(divHeight / lineHeight);
  };

  return (
    <CardContainer isExpanded={isExpanded}>
      <ImageContainer>
        {resource?.resourceImage?.image && (
          <Image
            src={
              resource?.resourceImage?.image?.formats?.small?.url
                ? resource.resourceImage.image.formats.small.url
                : resource.resourceImage.image.url
            }
            alt={resource.title}
          ></Image>
        )}
      </ImageContainer>
      <Main>
        <ResourceTitle>{resource.title}</ResourceTitle>
        <Description
          descriptionLinesCount={descriptionLinesCount}
          isExpanded={isExpanded}
        >
          {resource.description && ( //description section is showing only when there is description
            <Body3 ref={descriptionRef}>
              {isExpanded
                ? resource.description
                : resource.description.length > characterLimit
                ? `${resource.description.substring(0, characterLimit)}...`
                : resource.description}
              {descriptionLinesCount === descriptionLineLimit ? (
                <Button onClick={() => setIsExpanded(!isExpanded)}>
                  <Body4 color={colors.bluegreen}>
                    {isExpanded ? 'Less' : 'More'}
                  </Body4>
                </Button>
              ) : (
                ''
              )}
            </Body3>
          )}
        </Description>
        <LinkSection>
          <LinkIcon src={link} alt="link" />
          <ResourceLink href={resource.link} target="_blank">
            {getSubString(resource.link, resource.link.indexOf('/') + 2, 30)}
          </ResourceLink>
        </LinkSection>
      </Main>
    </CardContainer>
  );
};

const CardContainer = styled.div`
  width: 360px;
  height: ${(props) => (props.isExpanded ? 'auto' : '506px')};
  margin-top: 40px;
  background-color: ${colors.white};
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05),
    0px 30px 60px 5px rgba(49, 49, 49, 0.15);
`;

const ImageContainer = styled.div`
  height: 200px;
  background-color: ${() => getRandomColor()};
`;

const Image = styled.img`
  height: 100%;
  width: 100%;
  object-fit: cover;
`;

const Main = styled.div`
  margin: 40px 30px;
`;

const ResourceTitle = styled(TitleHeader4)`
  margin-bottom: 0px;
  line-height: 22px;
  height: 44px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

const Description = styled.div`
  min-height: 150px;
  margin-top: 7px;
  margin-bottom: 6px;
`;

const Button = styled.button`
  background: none;
  border: none;
  color: #00c2ba !important;
  text-decoration: underline;
  :hover {
    cursor: pointer;
  }
`;

const LinkSection = styled.div`
  display: flex;
`;
const LinkIcon = styled.img`
  width: 14px;
  height: 14px;
  margin-right: 5px;
`;
const ResourceLink = styled.a`
  font-family: 'Mulish-Regular';
  font-size: 14px;
  text-decoration: none;
  color: ${colors.bluegreen};
  line-height: 1;
`;

export default ResourceCard;
