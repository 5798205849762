import styled from '@emotion/styled';
import React from 'react';
import breakPoints from '../../assets/styles/breakpoints';

const Map = () => {
  return (
    <MapContainer>
      <iframe
        title="google map"
        width="100%"
        height="100%"
        loading="lazy"
        allowFullScreen
        src="https://www.google.com/maps/embed/v1/place?q=place_id:ChIJSTjdRm9zhlQRnsqIyWupKjs&key=AIzaSyBm0g_WU1TrnQxgFl_m1z1IV-W29v4xoDA"
      ></iframe>
    </MapContainer>
  );
};

const MapContainer = styled.div`
  margin-top: 70px;
  width: 100%;
  height: 780px;
  @media only screen and (max-width: ${breakPoints.mobile}) {
    height: 390px;
    width: calc(100% + 40px);
    margin-left: -20px;
    margin-right: -20px;
  }
`;

export default Map;
