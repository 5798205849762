import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import xIcon from '../../assets/images/x-icon.svg';

const QuitProgressModal = (props) => {
  const { openModal, handleChange, closeParentModal } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [quit, setQuit] = useState(false);

  useEffect(() => {
    setIsOpen(openModal);
  }, [openModal]);

  const close = () => {
    handleChange(isOpen);
  };

  const quitProgress = () => {
    setQuit(true);
    handleChange(isOpen);
    closeParentModal(quit);
  };

  return (
    openModal && (
      <Overlay>
        <QuitModal>
          <Contents>
            <Text>Do you want to quit your progress?</Text>
            <NavigationButtons>
              <button id="no" onClick={close}>
                No
              </button>
              <button id="yes" onClick={quitProgress}>
                Yes
              </button>
            </NavigationButtons>
            <ExitButton onClick={close}>
              <img src={xIcon} alt="x icon" />
            </ExitButton>
          </Contents>
        </QuitModal>
      </Overlay>
    )
  );
};

const Overlay = styled.div`
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const QuitModal = styled.div`
  height: 228px;
  background: #ffffff;
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05),
    0px 30px 60px 5px rgba(49, 49, 49, 0.15);
  border-radius: 10px;
  display: flex;
  align-items: center;
  position: relative;
  z-index: 10;
`;

const Contents = styled.div`
  padding: 50px 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Text = styled.p`
  font-family: Khula-Bold;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 25px;
  letter-spacing: -0.25px;
  text-transform: capitalize;
  color: #121212;
`;

const NavigationButtons = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 25px;

  button {
    font-family: Khula-Bold;
    font-size: 16px;
    line-height: 25px;
    text-align: center;
    width: 129px;
    height: 40px;
    border-radius: 50px;
    cursor: pointer;
    padding-top: 5px;
  }

  #no {
    color: #fab550;
    background-color: #ffffff;
    border: 1px solid #fab550;
    margin-right: 10px;
  }

  #yes {
    color: #ffffff;
    background-color: #fab550;
    border: none;
  }
`;

const ExitButton = styled.button`
  border: none;
  background: none;
  position: absolute;
  cursor: pointer;
  top: 0;
  right: 0;
  padding: 20px;
`;

export default QuitProgressModal;
