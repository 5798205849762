import styled from '@emotion/styled';
import dateFormat from 'dateformat';
import React from 'react';
import { Body3 } from '../../assets/styledComponents/Body3';
import { Body5 } from '../../assets/styledComponents/Body5';
import { DateHeader5 } from '../../assets/styledComponents/DateHeader5';
import { TitleHeader4 } from '../../assets/styledComponents/TitleHeader4';
import colors from '../../assets/styles/colors';
import ExpandedStoryMediaCarousel from './ExpandedStoryMediaCarousel';

const ExpandedStoryCard = ({
  mediaContent,
  date_stamp,
  title,
  description,
  name,
  postalCode,
  cardWithMedia,
}) => {
  const city = postalCode && postalCode.substring(5, postalCode.length);

  return (
    <Container cardWithMedia={cardWithMedia}>
      {cardWithMedia && (
        <ImageContainer>
          <ExpandedStoryMediaCarousel mediaContent={mediaContent} />
        </ImageContainer>
      )}
      <StoryContentWrapper>
        <DateHeader5>{dateFormat(date_stamp, 'yyyy.mm.dd')}</DateHeader5>
        <Header>{title}</Header>
        <Description
          dangerouslySetInnerHTML={{ __html: description }}
        ></Description>
        <NameAndCity>{`${name}${name && city ? ',' : ''}${city}`}</NameAndCity>
      </StoryContentWrapper>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: stretch;
  justify-content: center;
  width: ${(props) => (props.cardWithMedia ? '1160px' : '360px')};
  max-width: 100vw;
  height: 720px;
  max-height: 100vh;
`;

const ImageContainer = styled.div`
  width: 800px;
  max-width: 65vw;
  height: 720px;
  max-height: 100vh;
`;

const StoryContentWrapper = styled.div`
  background-color: ${colors.white};
  padding: 40px;
  width: 360px;
  overflow: auto;
`;

const Header = styled(TitleHeader4)`
  margin-top: 10px;
`;
const Description = styled(Body3)`
  margin-top: 30px;
`;

const NameAndCity = styled(Body5)`
  margin-top: 30px;
  text-align: right;
`;

export default ExpandedStoryCard;
