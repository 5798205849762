import styled from '@emotion/styled';
import React from 'react';

const ModalOverlay = ({ showModal, setShowModal }) => {
  const handleClick = () => {
    setShowModal(false);
  };

  return showModal && <StyledBackground onClick={handleClick} />;
};

const StyledBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 5;
  background: rgba(0, 0, 0, 0.5);
  :hover {
    cursor: pointer;
  }
`;

export default ModalOverlay;
