import styled from '@emotion/styled';
import colors from '../styles/colors';
import breakPoints from 'assets/styles/breakpoints';

export const PolicyDiv = styled.div`
  margin: 0 140px 130px;
  strong {
    margin-top: 20px;
  }
  p {
    margin-top: 10px;
    font-size: 1.1em;
    line-height: 1.7;
    margin-bottom: 5px;
    color: ${colors.almostBlack};
  }
  h4 {
    margin-bottom: 20px;
    color: ${colors.almostBlack};
  }
  h3 {
    margin-bottom: 20px;
    color: ${colors.almostBlack};
  }
  a {
    font-family: 'Mulish-Regular';
    word-break: break-word;
    color: ${colors.almostBlack};
    text-decoration: underline;
  }
  ul {
    font-family: 'Mulish-Regular';
    text-indent: 2ch;
    list-style: none;
    li:before {
      content: '- ';
    }
  }

  @media only screen and (max-width: ${breakPoints.tablet}) {
    margin: 0 0 25px;
    padding: 0px 20px;
  }
`;
