import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import colors from '../../assets/styles/colors';
import VerticalScrub from '../../components/landing_components/VerticalScrub';

export default function TimelineScubber({
  setSelectedYear,
  yearArray,
  selectedYear,
}) {
  const [yPositions, setYPositions] = useState([]);
  const [highlightedYear, setHighlightedYear] = useState(0);
  const elemIds = [...yearArray];

  //collects the y-positions of all elements we want to jump to via scrubber
  useEffect(() => {
    const yPosArr = [];

    for (const key in elemIds) {
      //this calculates offset from top of page. If we implement a 'scrolltotop' when we change routes, this can be reduced to pushing the elemRect, I think.
      const pageTop = document.body.getBoundingClientRect().top;
      const elemRect =
        document.getElementById(elemIds[key]).getBoundingClientRect().top + 150;
      const offset = elemRect - pageTop;
      yPosArr.push(offset);
    }
    setYPositions(yPosArr);
    //eslint-disable-next-line
  }, [yearArray]);

  //part of click-navigation for scrubber
  const handleClick = (e) => {
    setSelectedYear(e.target.innerText);
  };

  //conditionally adds class with scroll-padding to elements we jump to via scrubber
  const handleClass = (ind) => {
    if (ind !== yearArray.length - 1) {
      return 'with-margin ';
    }
  };

  const renderYears = () => {
    return yearArray.map((elem, ind) => (
      <ScrubberItem
        className={`${handleClass(ind)} ${ind === 0 && 'selected'}`}
        id={`scrubber-${elem}`}
        key={`year-${ind}`}
        onClick={handleClick}
      >
        {elem}
      </ScrubberItem>
    ));
  };

  //updates the highlighted year as you scroll
  const highlightScrub = (posArr, elemId) => {
    let ind = posArr.findIndex((elem) => elem >= window.scrollY);
    if (document.getElementsByClassName('selected').length) {
      document
        .getElementsByClassName('selected')[0]
        .classList.remove('selected');
      document
        .getElementById(
          `scrubber-${
            window.scrollY <= posArr[1]
              ? elemId[0]
              : ind < 0
              ? elemId[elemId.length - 1]
              : elemId[ind - 1]
          }`
        )
        .classList.add('selected');
      setHighlightedYear(
        window.scrollY <= posArr[1] ? 0 : ind < 0 ? posArr.length - 1 : ind - 1
      );
    }
  };

  useEffect(() => {
    //isMounted exists to cleanup memory leak warning
    let isMounted = true;
    window.addEventListener(
      'scroll',
      () => isMounted && highlightScrub(yPositions, elemIds)
    );

    return () => {
      isMounted = false;
      window.removeEventListener('scroll', () =>
        highlightScrub(yPositions, elemIds)
      );
    };
  });

  return (
    <StickyWrapper>
      <ScrubberWrapper size={elemIds.length}>
        <YearWrapper>{renderYears()}</YearWrapper>
        <VerticalScrub
          selectedYear={selectedYear}
          highlightedYear={highlightedYear}
        />
      </ScrubberWrapper>
    </StickyWrapper>
  );
}

const ScrubberWrapper = styled.div`
  position: sticky;
  display: flex;
  justify-content: flex-end;
  margin-right: 35px;
  margin-top: 100px;
  margin-bottom: -${(props) => props.size * 25 + (props.size - 1) * 40 + 120}px;
  z-index: 10;
`;

const YearWrapper = styled.div`
  padding: 10px 0;
  pointer-events: all;
  margin-right: 15px;
  li {
    list-style: none;
  }
  .with-margin {
    margin-bottom: 40px;
  }
  .selected {
    color: ${colors.bluegreen};
  }
`;

const StickyWrapper = styled.div`
  position: sticky;
  pointer-events: none;
  top: 10rem;
  z-index: 10;
  @media only screen and (max-width: 1280px) {
    display: none;
  }
`;

const ScrubberItem = styled.li`
  color: ${colors.lightBluegreen};
  font-family: 'Khula-SemiBold', sans-serif;
  line-height: 25px;
  cursor: pointer;
`;
