import styled from '@emotion/styled';
import { React } from 'react';
import { connectPagination } from 'react-instantsearch-dom';
import colors from 'assets/styles/colors';

const Pagination = ({ currentRefinement, nbPages, refine, createURL }) => (
  <PaginationList>
    {nbPages > 0 && (
      <Arrow
        onClick={() => {
          if (currentRefinement !== 1) {
            refine(currentRefinement - 1);
            window.scrollTo({
              top: 0,
              left: 0,
              behavior: 'auto',
            });
          }
        }}
        className={`${currentRefinement !== 1 ? 'active' : ''}`}
      >
        ‹
      </Arrow>
    )}
    {new Array(nbPages).fill(null).map((_, index) => {
      const page = index + 1;
      const style = {
        backgroundColor: currentRefinement === page ? colors.lightYellow : '',
        color: currentRefinement === page ? 'white' : '',
      };

      return (
        <PageLink
          key={index}
          href={createURL(page)}
          style={style}
          onClick={() => {
            refine(page);
          }}
        >
          {page}
        </PageLink>
      );
    })}
    {nbPages > 0 && (
      <Arrow
        onClick={() => {
          if (currentRefinement !== nbPages) {
            refine(currentRefinement + 1);
            window.scrollTo({
              top: 0,
              left: 0,
              behavior: 'auto',
            });
          }
        }}
        className={`${currentRefinement !== nbPages ? 'active' : ''}`}
      >
        ›
      </Arrow>
    )}
  </PaginationList>
);

const PaginationList = styled.ul`
  display: flex;
  padding-top: 40px;
  list-style: none;

  li {
    cursor: default;
    color: grey;
  }

  li.active {
    cursor: pointer;
    color: black;
  }
`;

const Arrow = styled.li`
  font-size: 24px;
  padding-right: 10px;
  padding-left: 10px;
`;

const PageLink = styled.a`
  color: black;
  border-radius: 100px;
  float: left;
  padding: 8px 16px;
  font-family: Mulish-Regular;
  text-decoration: none;
  transition: background-color 0.3s;
  margin-left: 5px;
  :hover {
    background-color: #ddd;
  }
`;

const CustomPagination = connectPagination(Pagination);

export default CustomPagination;
