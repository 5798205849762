import styled from '@emotion/styled';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import colors from '../../assets/styles/colors';

const CropImage = ({ upImg, handleChancelImgCropClick, handleCropClick }) => {
  const imgRef = useRef(null);
  const [crop, setCrop] = useState({
    unit: '%',
    width: 80,
    aspect: 1 / 1,
  });
  const [completedCrop, setCompletedCrop] = useState(null);
  const [canvas] = useState(document.createElement('canvas'));

  const onLoad = useCallback((img) => {
    imgRef.current = img;
  }, []);

  useEffect(() => {
    if (!completedCrop || !imgRef.current) {
      return;
    }

    const image = imgRef.current;
    const crop = completedCrop;

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext('2d');

    // New lines to be added
    const pixelRatio = window.devicePixelRatio;
    canvas.width = crop.width * pixelRatio;
    canvas.height = crop.height * pixelRatio;
    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = 'high';

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );
  }, [completedCrop, canvas]);

  return (
    <Container>
      <ImageContainer>
        <ReactCrop
          src={upImg}
          onImageLoaded={onLoad}
          crop={crop}
          onChange={(c) => setCrop(c)}
          onComplete={(c) => setCompletedCrop(c)}
          ruleOfThirds
          imageStyle={{
            width: '100%',
            height: '655px',
            objectFit: 'contain',
          }}
        />
      </ImageContainer>
      <NavigationButtons>
        <button id="cancel" onClick={handleChancelImgCropClick}>
          Cancel
        </button>
        <button
          id="crop"
          onClick={() => handleCropClick(canvas, completedCrop)}
        >
          Crop
        </button>
      </NavigationButtons>
    </Container>
  );
};

const Container = styled.div``;
const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;
  height: 655px;
  background-color: ${colors.lightGrey};
`;

const NavigationButtons = styled.div`
  margin: 40px 0px 30px;
  display: flex;
  justify-content: center;

  button {
    font-family: Khula-Bold;
    font-size: 16px;
    line-height: 25px;
    text-align: center;
    width: 148px;
    height: 40px;
    border-radius: 50px;
    cursor: pointer;
    padding-top: 5px;
  }

  #cancel {
    color: ${colors.darkYellow};
    background: ${colors.white};
    border: 1px solid ${colors.darkYellow};
    margin-right: 10px;
  }

  #crop {
    color: ${colors.white};
    background: ${colors.darkYellow};
    cursor: pointer;
    border: none;
  }
`;

export default CropImage;
