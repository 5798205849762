import styled from '@emotion/styled';
import closeIcon from 'assets/images/icon_close.svg';
import colors from 'assets/styles/colors';
import React from 'react';
import { connectCurrentRefinements } from 'react-instantsearch-dom';

const ClearRefinements = ({ items, refine }) => {
  return (
    <Pill>
      <p>Clear All</p>
      <button onClick={() => refine(items)} items={items}></button>
    </Pill>
  );
};

const Pill = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  background-color: ${colors.bluegreen};
  font-family: 'Mulish-Regular', sans-serif;
  font-weight: 600;
  font-size: 0.875rem;
  letter-spacing: 0.035rem;
  padding: 7px 12px;
  width: fit-content;
  margin-right: 6px;
  margin-bottom: 10px;
  button {
    margin-left: 4px;
    margin-bottom: 3px;
    width: 16px;
    height: 16px;
    border: none;
    background-color: transparent;
    padding: 0;
    display: flex;
    cursor: pointer;
    background-image: url(${closeIcon});
    background-size: 16px 16px;
  }
`;

export default connectCurrentRefinements(ClearRefinements);
