import styled from '@emotion/styled';
import colors from 'assets/styles/colors';
import React from 'react';

const Checkbox = ({ id, ref, handleFilter, checked }) => {
  return (
    <Container>
      <label className="container">
        <input
          type="checkbox"
          id={id}
          ref={ref}
          onClick={handleFilter}
          checked={checked}
          onChange={() => {}}
        />
        <span className="checkmark"></span>
      </label>
    </Container>
  );
};

const Container = styled.main`
  /* The container */
  .container {
    display: block;
    position: relative;
    padding-left: 21px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  /* Hide the browser's default checkbox */
  .container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    top: 4px;
    left: 0;
    height: 12px;
    width: 12px;
    border: 1px solid ${colors.darkGrey};
  }

  /* When the checkbox is checked, add a blue background */
  .container input:checked ~ .checkmark {
    height: 14px;
    width: 14px;
    background-color: ${colors.bluegreen};
    border: none;
  }

  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: '';
    position: absolute;
    display: none;
  }

  /* Show the checkmark when checked */
  .container input:checked ~ .checkmark:after {
    display: block;
  }

  /* Style the checkmark/indicator */
  .container .checkmark:after {
    left: 4px;
    top: 2px;
    width: 3px;
    height: 7px;
    border: solid white;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
`;

export default Checkbox;
