/*eslint-disable*/
import styled from '@emotion/styled';
import closeIcon from 'assets/images/icon_close.svg';
import searchIcon from 'assets/images/icon_search.svg';
import breakPoints from 'assets/styles/breakpoints';
import colors from 'assets/styles/colors';
import React, { useEffect, useState } from 'react';
import { connectSearchBox } from 'react-instantsearch-dom';

const StorySearchBar = ({
  setSearchTerm,
  translations,
  currentRefinement,
  searchTerm,
  refine,
}) => {
  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    if (searchTerm === '') {
      handleClose();
    }
  }, [searchTerm]);

  const handleExpand = () => {
    setExpanded(!expanded);
  };

  const handleSearchQuery = (e) => {
    refine(e.currentTarget.value);
    setSearchTerm(e.target.value);
  };

  const handleClose = () => {
    setSearchTerm('');
    refine('');
    setExpanded(false);
  };

  return (
    <SearchBarWrapper>
      <ExpandingDiv expanded={expanded}>
        {!expanded && (
          <StyledButton
            dimensions="36"
            iconSize="24"
            image={searchIcon}
            onClick={handleExpand}
          />
        )}
        {expanded && (
          <>
            <StyledImg alt="search magnifying glass" src={searchIcon} />
            <ExpandedInput
              placeholder={translations.placeholder}
              onChange={handleSearchQuery}
              value={currentRefinement}
            />
            <StyledButton
              dimensions="36"
              iconSize="16"
              image={closeIcon}
              onClick={handleClose}
            />
          </>
        )}
      </ExpandingDiv>
    </SearchBarWrapper>
  );
};

const SearchBarWrapper = styled.div`
  position: relative;
  margin-left: 40px;
`;

const StyledImg = styled.img`
  margin-left: 6px;
`;

const ExpandedInput = styled.input`
  border: none;
  padding: 0;
  font-size: 0.875rem;
  font-family: 'Mulish-Regular', sans-serif;
  letter-spacing: 0.035rem;
  line-height: 24px;
  text-indent: 4px;

  @media only screen and (max-width: ${breakPoints.mobile}) {
    width: 80%;
  :focus {
    outline: none;
  }
`;

const StyledButton = styled.button`
  width: ${(props) => props.dimensions}px;
  height: ${(props) => props.dimensions}px;
  border: none;
  border-radius: 50%;
  background-color: transparent;
  padding: 0;
  display: flex;
  cursor: pointer;
  background-image: url(${(props) => props.image});
  background-size: ${(props) => `${props.iconSize}px ${props.iconSize}px`};
  background-repeat: no-repeat;
  background-position: center;
  :hover {
    background-color: #e2f5fe80;
  }
  :active {
    background-color: ${colors.lightBlue};
  }
`;

const ExpandingDiv = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 0;
  right: 0;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.15);
  background-color: ${colors.white};
  border-radius: 20px;
  transition: 0.2s ease-in-out;
  height: 36px;
  ${(props) =>
    props.expanded
      ? `    
    justify-content: space-between;
    width: 250px;`
      : `
    justify-content: center;
    width: 36px;
`}
  img {
    height: 24px;
    width: 24px;
  }

  @media only screen and (max-width: ${breakPoints.mobile}) {
    right: -35px;
    ${(props) =>
      props.expanded
        ? `    
          justify-content: space-between;
          width: 345px;
          z-index: 56;
          right: -345px;
           img {
            margin: 0 10px;
          }
          `
        : `
          justify-content: center;
          width: 36px;
          `}
  }
`;

export default connectSearchBox(StorySearchBar);
