export const pixels = {
  min: 320,
  mobile: 600,
  tablet: 960,
  desktop: 1440,
  max: 2560,
};

const breakPoints = {
  min: `${pixels.min}px`,
  mobile: `${pixels.mobile}px`,
  tablet: `${pixels.tablet}px`,
  desktop: `${pixels.desctop}px`,
  max: `${pixels.max}px`,
};

export default breakPoints;
