import styled from '@emotion/styled';
import React, { useContext, useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { GlobalContext } from 'contexts/global/GlobalContext';
import Footer from 'components/Footer';
import NavBar from 'components/Navbar/Navbar';
import ContactUsPage from 'pages/ContactUs/ContactUsPage';
import LandingPage from 'pages/LandingPage';
import OurMissionPage from 'pages/OurMission/OurMissionPage';
import PrivacyPolicyPage from 'pages/PrivacyPolicy/PrivacyPolicyPage';
import ResourcesPage from 'pages/Resources/ResourcesPage';
import StoriesPage from 'pages/StoriesPage';
import ScrollToTop from 'utils/ScrollToTop';
import breakPoints from 'assets/styles/breakpoints';

const Routes = () => {
  const [, globalDispatch] = useContext(GlobalContext);

  const handleMediaQuery = () => {
    let mediaQueryListMobile = matchMedia(`(max-width: ${breakPoints.tablet})`);
    if (mediaQueryListMobile.matches) {
      globalDispatch({
        type: 'UPDATE_IS_MOBILE',
        payload: true,
      });
    } else {
      globalDispatch({
        type: 'UPDATE_IS_MOBILE',
        payload: false,
      });
    }
  };

  useEffect(() => {
    handleMediaQuery();
    window.addEventListener('resize', handleMediaQuery);
    return () => {
      window.removeEventListener('resize', handleMediaQuery);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ContentWrapper>
      <MainContent>
        <Router>
          <ScrollToTop>
            <NavBar />
            <Switch>
              <Route exact path="/stories" component={StoriesPage} />
              <Route exact path="/policy" component={PrivacyPolicyPage} />
              <Route exact path="/ourmission" component={OurMissionPage} />
              <Route exact path="/resources" component={ResourcesPage} />
              <Route exact path="/contactus" component={ContactUsPage} />
              {/* catch all route*/}
              <Route exact path="/" component={LandingPage} />
            </Switch>
          </ScrollToTop>
        </Router>
      </MainContent>
      <Footer />
    </ContentWrapper>
  );
};

const ContentWrapper = styled.div`
  display: flex;
  min-height: 100vh;
  flex-direction: column;
`;

const MainContent = styled.div`
  flex: 1;
`;
export default Routes;
