import styled from '@emotion/styled';
import React, { useEffect, useState } from 'react';
import arrowDown from '../../assets/images/arrow-down.svg';
import { Body4 } from '../../assets/styledComponents/Body4';
import breakPoints, { pixels } from '../../assets/styles/breakpoints';
import colors from '../../assets/styles/colors';
import ResourcesCategories from '../../types/ResourcesCategories';

const ResourceNavigation = ({
  resourcesCategories,
  handleCategoryChange,
  selectedCategory,
}) => {
  const [toggleMenu, setToggleMenu] = useState(false);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  const toggleNav = () => {
    setToggleMenu(!toggleMenu);
  };

  const toggleMobileNav = () => {
    if (screenWidth < pixels.mobile) toggleNav();
  };

  const onNavigationItemClick = (item) => {
    setToggleMenu(!setToggleMenu);
    handleCategoryChange(item);
  };

  useEffect(() => {
    const changeWidth = () => {
      setScreenWidth(window.innerWidth);
    };
    window.addEventListener('resize', changeWidth);
    return () => {
      window.removeEventListener('resize', changeWidth);
    };
  }, []);

  return (
    <div>
      <NavigationContainer>
        {toggleMenu || screenWidth > pixels.mobile ? (
          <NavigationPanel>
            {resourcesCategories.map((item) => (
              <NavigationItem
                key={item}
                onClick={() => onNavigationItemClick(item)}
                isActive={item === selectedCategory}
              >
                <Body4 color={colors.white}>{ResourcesCategories[item]}</Body4>
              </NavigationItem>
            ))}
          </NavigationPanel>
        ) : (
          <ItemContainer onClick={toggleMobileNav}>
            <Body4 color={colors.white}>
              {ResourcesCategories[selectedCategory]}
            </Body4>
          </ItemContainer>
        )}
        <Button onClick={toggleNav}>
          <img src={arrowDown} alt="arrow down" />
        </Button>
      </NavigationContainer>
    </div>
  );
};

const NavigationContainer = styled.nav`
  position: relative;
  max-width: 1160px;
  min-height: 60px;
  margin: auto;
  background-color: ${colors.darkNavy};

  @media only screen and (max-width: ${breakPoints.tablet}) {
    height: 100%;
    width: auto;
    margin: 20px;
  }

  @media only screen and (max-width: ${breakPoints.mobile}) {
    width: 348px;
    margin: 0 auto 25px;
    min-height: 50px;
  }
`;

const NavigationPanel = styled.ul`
  min-height: 60px;
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  position: relative;
  list-style: none;

  @media only screen and (max-width: ${breakPoints.mobile}) {
    flex-direction: column;
  }
`;

const NavigationItem = styled.li`
  flex: 1;
  text-align: center;
  padding: 18px 0;
  background-color: ${(props) => (props.isActive ? colors.bluegreen : '')};
  font-weight: ${(props) => (props.isActive ? 700 : 400)};
  :hover {
    cursor: pointer;
    background-color: ${(props) =>
      props.isActive ? colors.bluegreen : 'rgba(255, 255, 255, 0.3)'};
  }

  @media only screen and (max-width: ${breakPoints.mobile}) {
    width: 100%;
    padding: 13px 0;
  }
`;

const ItemContainer = styled.div`
  text-align: center;
  padding: 13px 0;
  background-color: ${colors.bluegreen};
`;

const Button = styled.button`
  display: none;
  position: absolute;
  right: 10px;
  top: 7px;
  padding: 5px;
  font-size: 18px;
  background-color: transparent;
  border: none;
  @media only screen and (max-width: ${breakPoints.mobile}) {
    display: block;
  }
  :hover {
    cursor: pointer;
  }
`;

export default ResourceNavigation;
