import styled from '@emotion/styled';
import React from 'react';
import Slider from 'react-slick';
import colors from '../styles/colors';
import breakPoints from 'assets/styles/breakpoints';

const ImageSlider = ({
  mediaContent,
  width,
  height,
  attributeMargin,
  dotsPadding,
  handleShowExpandedImage,
  isExpandedImage,
}) => {
  const settings = {
    speed: 500,
    arrows: true,
    infinite: false,
    lazyLoad: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: true,
  };

  return (
    <StyledSlider
      {...settings}
      width={width}
      height={height}
      arrowMargin={attributeMargin}
      dotsPadding={dotsPadding}
    >
      {mediaContent
        .filter((item) => item.image)
        .map((item) => (
          <Image
            src={item.image.url}
            key={item.image.url}
            alt={item.image.name}
            onClick={handleShowExpandedImage}
            isExpandedImage={isExpandedImage}
          ></Image>
        ))}
    </StyledSlider>
  );
};

const StyledSlider = styled(Slider)`
.slick-slide {
    & > div {
      display: flex;
      align-items: center;
      justify-content: center;
      width: ${(props) => props.width};
      max-width: 100vw;
      height: ${(props) => props.height};
      max-height: 100vh;
    }
  }

  button {
    z-index: 1;

    &:before {
      font-size: 36px;
    }
  }

  .button {
    display: none;
  }
/* Style for arrows */
  &:hover {
    .slick-arrow {
      display: block !important;
    }
  }

  .slick-arrow {
    display: none !important;
    width: 36px;
    height: 36px;

    &.slick-prev {
      left: ${(props) => props.arrowMargin};
    }
    &.slick-next {
      right: ${(props) => props.arrowMargin};
    }
  }


/* Style for dots */
.slick-dots {
  padding-bottom: ${(props) => props.dotsPadding};
  z-index: 15;

 & li {margin: 0 0.2rem}

  & li button:before {
    width: 10px;
    height: 10px;
    font-size: 10px;
    opacity: 1;
    color: white;
  }
  & li {
    margin: 0 0.2rem;
    width: 10px;
    height: 10px;}
 
  & li button {
   width: 10px;
   height: 10px;
 }
  & li.slick-active button:before {
    opacity: 1;
    color: ${colors.lightYellow};
  }`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  ${(props) => (props.isExpandedImage ? 'object-fit: contain' : '')};

  @media only screen and (max-width: ${breakPoints.tablet}) {
    height: auto;
  }
`;

export default ImageSlider;
