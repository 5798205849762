const GlobalReducer = (state, action) => {
  switch (action.type) {
    case 'UPDATE_IS_MOBILE':
      return {
        ...state,
        isMobile: action.payload,
      };
    default:
      return state;
  }
};
export default GlobalReducer;
