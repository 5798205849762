import { useQuery } from '@apollo/client';
import styled from '@emotion/styled';
import React, { useEffect, useState, useContext } from 'react';
import { GlobalContext } from 'contexts/global/GlobalContext';
import breakPoints, { pixels } from '../../assets/styles/breakpoints';
import colors from '../../assets/styles/colors';
import { GET_RESOURCES } from '../../graphql/queries';
import ResourcesCategories from '../../types/ResourcesCategories';
import ResourceCard from './ResourceCard';
import SmallResourceCard from './SmallResourseCard';
import LoadingSpinner from 'assets/styledComponents/LoadingSpinner';

const CardSection = ({ resourcesCategories, selectedCategory }) => {
  const [{ isMobile }] = useContext(GlobalContext);
  const {
    loading: resourcesLoading,
    data: resourcesData,
    error: resourcesError,
  } = useQuery(GET_RESOURCES);

  const ALL_CATEGORIES = 'ALL';
  const [viewportWidth, setViewportWidth] = useState(getWindowWidth());

  function getWindowWidth() {
    // Get width of the window excluding scrollbars
    return document.documentElement.clientWidth;
  }

  useEffect(() => {
    const onChangeWindowSize = () => {
      if (getWindowWidth() === visualViewport) {
        return;
      }
      setViewportWidth(getWindowWidth());
    };
    window.addEventListener('resize', onChangeWindowSize);
    return () => {
      window.removeEventListener('resize', onChangeWindowSize);
    };
  }, [viewportWidth]);

  const categoryPredicate = (categoryName) => {
    switch (selectedCategory) {
      case ALL_CATEGORIES:
        return true;
      default: {
        return categoryName === selectedCategory;
      }
    }
  };

  return (
    <Container>
      {resourcesCategories
        .filter((categoryName) => categoryName !== ALL_CATEGORIES)
        .filter((categoryName) => categoryPredicate(categoryName))
        .map((item) => (
          <CategoryContainer key={item}>
            <SectionHeaderContainer key={item}>
              {isMobile ? (
                <h4>{ResourcesCategories[item]}</h4>
              ) : (
                <h3>{ResourcesCategories[item]}</h3>
              )}
            </SectionHeaderContainer>
            <CardsContainer>
              {resourcesData &&
                resourcesData.resources
                  .filter((val) => val.tag === item)
                  .map((item) =>
                    viewportWidth <= pixels.mobile ? (
                      <SmallResourceCard key={item.id} resource={item} />
                    ) : (
                      <ResourceCard key={item.id} resource={item} />
                    )
                  )}

              {resourcesLoading ? <LoadingSpinner /> : ''}
              {resourcesError ? <p>Error</p> : ''}
            </CardsContainer>
          </CategoryContainer>
        ))}
    </Container>
  );
};

const Container = styled.div`
  margin-top: -30px;

  @media only screen and (max-width: ${breakPoints.mobile}) {
    margin-top: -55px;
  }
`;

const CardsContainer = styled.div`
  max-width: 1160px;
  margin: auto;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 40px;
  @media only screen and (max-width: 1160px) {
    grid-template-columns: auto auto;
    justify-content: center;
  }
  @media only screen and (max-width: ${breakPoints.mobile}) {
    margin-bottom: 20px;
    justify-content: center;
    grid-template-columns: auto;
  }
`;

const CategoryContainer = styled.div`
  padding-top: 100px;
  padding-bottom: 110px;
  :nth-of-type(2n-1) {
    background-color: ${colors.lightBlue};
  }

  @media only screen and (max-width: ${breakPoints.tablet}) {
    padding: 50px 20px;
  }

  @media only screen and (max-width: ${breakPoints.mobile}) {
    padding-top: 35px;
    padding-bottom: 35px;
  }
`;

const SectionHeaderContainer = styled.div`
  max-width: 1160px;
  margin: auto;

  @media only screen and (max-width: ${breakPoints.tablet}) {
    margin: 25px 0 0;
  }
`;

export default CardSection;
