import { gql } from '@apollo/client';

export const UPLOAD_IMAGE = gql`
  mutation uploadImage($file: Upload!) {
    upload(file: $file) {
      id
      name
      url
    }
  }
`;

export const CREATE_STORY = gql`
  mutation createStory($data: StoryInput) {
    createStory(input: { data: $data }) {
      story {
        id
        _id
        createdAt
        updatedAt
        title
        name
        date
        postalCode
        storyCategory
        yourStory
        storyMediaContent {
          ... on ComponentStoryMediaContentVideoLink {
            id
            link
          }
          ... on ComponentStoryMediaContentImage {
            id
            image {
              id
              name
              size
              url
            }
          }
        }
        published_at
      }
    }
  }
`;

export const UPDATE_STORY = gql`
  mutation updateStory($id: ID!, $data: editStoryInput) {
    updateStory(input: { where: { id: $id }, data: $data }) {
      story {
        id
        _id
        createdAt
        updatedAt
        title
        name
        date
        postalCode
        storyCategory
        yourStory
        storyMediaContent {
          ... on ComponentStoryMediaContentVideoLink {
            id
            link
          }
          ... on ComponentStoryMediaContentImage {
            id
            image {
              id
              name
              size
              url
            }
          }
        }
        published_at
      }
    }
  }
`;
