import React, { createContext, useReducer } from 'react';
import GlobalReducer from './GlobalReducer';

const initialState = {
  isMobile: false,
};

export const GlobalContext = createContext(initialState);

const GlobalProvider = ({ children }) => {
  const [globalState, globalDispatch] = useReducer(GlobalReducer, initialState);

  return (
    <GlobalContext.Provider value={[globalState, globalDispatch]}>
      {children}
    </GlobalContext.Provider>
  );
};

export default GlobalProvider;
