import React from 'react';
import styled from '@emotion/styled';
import breakPoints from 'assets/styles/breakpoints';

const CardContainer = (props) => {
  return (
    <Container
      columns={props.columns}
      smallScreenColumns={props.smallScreenColumns}
      className={props.className}
    >
      {props.children}
    </Container>
  );
};

// Styled Components
const Container = styled.div`
  margin: 0px 60px;
  display: grid;
  grid-template-columns: repeat(${(props) => props.columns}, 1fr);
  justify-items: center;
  gap: 20px;

  @media only screen and (max-width: 1024px) {
    grid-template-columns: repeat(${(props) => props.smallScreenColumns}, 1fr);
  }

  @media only screen and (max-width: ${breakPoints.tablet}) {
    margin: 0px;
  }
`;

export default CardContainer;
