import { ApolloClient, InMemoryCache } from '@apollo/client';
import { createUploadLink } from 'apollo-upload-client';

const cache = new InMemoryCache();

export const client = new ApolloClient({
  cache,
  link: createUploadLink({
    uri: `${process.env.REACT_APP_API_URL}/graphql`,
  }),
});
