import styled from '@emotion/styled';
import React, { useCallback, useState } from 'react';
import link from '../../assets/images/link.svg';
import { Body5 } from '../../assets/styledComponents/Body5';
import Header5 from '../../assets/styledComponents/Header5';
import colors from '../../assets/styles/colors';
import getSubString from '../../utils/getSubString';

const SmallResourceCard = ({ resource }) => {
  const [descriptionLinesCount, descriptionRef] = useElementLinesCount();
  const [isExpanded, setIsExpanded] = useState(false);
  const descriptionLineLimit = 4;
  const characterLimit = 140;

  function useElementLinesCount() {
    const [count, setCount] = useState(null);
    const ref = useCallback((node) => {
      if (node !== null) {
        setCount(countLinesInElement(node));
      }
    }, []);
    return [count, ref];
  }

  const countLinesInElement = (el) => {
    let divHeight = el.offsetHeight;
    let lineHeight = 17; // line height for card description
    return Math.round(divHeight / lineHeight);
  };

  return (
    <CardContainer isExpanded={isExpanded}>
      <Main>
        <Title>{resource.title}</Title>
        <Description
          descriptionLinesCount={descriptionLinesCount}
          isExpanded={isExpanded}
        >
          {resource.description && ( //description section is showing only when there is description
            <Body5 ref={descriptionRef}>
              {isExpanded
                ? resource.description
                : resource.description.length > characterLimit
                ? `${resource.description.substring(0, characterLimit)}...`
                : resource.description}
              {descriptionLinesCount === descriptionLineLimit ? (
                <Button onClick={() => setIsExpanded(!isExpanded)}>
                  <Body5Styled color={colors.bluegreen}>
                    {isExpanded ? 'Less' : 'More'}
                  </Body5Styled>
                </Button>
              ) : (
                ''
              )}
            </Body5>
          )}
        </Description>
        <LinkSection>
          <LinkIcon src={link} alt="link" />
          <ResourceLink href={resource.link} target="_blank">
            {getSubString(resource.link, resource.link.indexOf('/') + 2, 30)}
          </ResourceLink>
        </LinkSection>
      </Main>
    </CardContainer>
  );
};

const CardContainer = styled.div`
  width: 348px;
  height: ${(props) => (props.isExpanded ? 'auto' : '198px')};
  margin-top: 20px;
  background-color: ${colors.white};
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05),
    0px 30px 60px 5px rgba(49, 49, 49, 0.15);
`;

const Main = styled.div`
  margin: 27px 30px 30px 30px;
`;

const Title = styled(Header5)`
  margin-bottom: 0px;
  height: 33px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

const Description = styled.div`
  min-height: 65px;
  margin-top: 10px;
  margin-bottom: 12px;
`;

const Button = styled.button`
  background: none;
  border: none;
  :hover {
    cursor: pointer;
  }
`;

const Body5Styled = styled(Body5)`
  color: ${colors.bluegreen};
`;

const LinkSection = styled.div`
  display: flex;
`;
const LinkIcon = styled.img`
  width: 14px;
  height: 14px;
  margin-right: 3px;
`;
const ResourceLink = styled.a`
  font-family: 'Mulish-Regular';
  font-size: 14px;
  text-decoration: none;
  color: ${colors.bluegreen};
  line-height: 1;
`;

export default SmallResourceCard;
