import styled from '@emotion/styled';
import closeIcon from 'assets/images/icon_close.svg';
import { Body3 } from 'assets/styledComponents/Body3';
import colors from 'assets/styles/colors';
import React from 'react';
import { connectCurrentRefinements } from 'react-instantsearch-dom';
import CustomClearRefinements from './CustomClearRefinements';
import breakPoints from 'assets/styles/breakpoints';

const CurrentRefinements = ({ items, refine }) => (
  <List>
    {items.map((item) => (
      <li key={item.label}>
        {item.items && (
          <React.Fragment>
            <Body3>
              filter by{' '}
              <b>
                {item.attribute === 'storyCategory'
                  ? 'Covid-19 Event'
                  : 'SNS Hashtag'}
              </b>
            </Body3>
            <InnerList>
              {item.items.map((nested) => (
                <InnerListItem key={nested.label}>
                  <Pill>
                    <p>
                      {item.attribute === 'storyCategory' ? '' : '#'}
                      {nested.label}
                    </p>
                    <button
                      onClick={(event) => {
                        event.preventDefault();
                        refine(nested.value);
                      }}
                    ></button>
                  </Pill>
                </InnerListItem>
              ))}
              <InnerListItem>
                <CustomClearRefinements />
              </InnerListItem>
            </InnerList>
          </React.Fragment>
        )}
      </li>
    ))}
  </List>
);

const List = styled.ul`
  list-style-type: none;
  @media only screen and (max-width: ${breakPoints.mobile}) {
    width: 100%;
    overflow: scroll;
    ::-webkit-scrollbar {
      display: none;
    }
  }
`;

const InnerList = styled.div`
  display: flex;
  flex-wrap: wrap;
  @media only screen and (max-width: ${breakPoints.mobile}) {
    flex-wrap: nowrap;
  }
`;

const InnerListItem = styled.div`
  display: inline;
  margin-top: 12px;
  @media only screen and (max-width: ${breakPoints.mobile}) {
    width: auto;
    white-space: nowrap;
  }
`;

const Pill = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  background-color: ${colors.lightBlue};
  font-family: 'Mulish-Regular', sans-serif;
  font-weight: 600;
  font-size: 0.875rem;
  letter-spacing: 0.035rem;
  padding: 7px 12px;
  width: fit-content;
  margin-right: 6px;
  margin-bottom: 10px;

  cursor: default;
  button {
    margin-left: 4px;
    margin-bottom: 3px;
    width: 16px;
    height: 16px;
    border: none;
    background-color: transparent;
    padding: 0;
    display: flex;
    cursor: pointer;
    background-image: url(${closeIcon});
    background-size: 16px 16px;
  }

  @media only screen and (max-width: ${breakPoints.mobile}) {
    width: auto;
    white-space: nowrap;
  }
`;

export default connectCurrentRefinements(CurrentRefinements);
