import React from 'react';
import styled from '@emotion/styled';
import breakPoints from 'assets/styles/breakpoints';

const Section = (props) => {
  return (
    <MainSection className={props.className}>{props.children}</MainSection>
  );
};
// Styled Components
const MainSection = styled.div`
  box-sizing: border-box;
  width: 80%;
  margin: 0 auto 120px;

  @media only screen and (max-width: ${breakPoints.tablet}) {
    width: auto;
    margin: 0 20px 60px;
  }
`;

export default Section;
