import styled from '@emotion/styled';
import React from 'react';
import { connectRefinementList } from 'react-instantsearch-dom';
import Checkbox from './Checkbox';
import ClearAllFilters from './ClearAllFilters';

const RefinementList = ({ items, refine, hashTag }) => {
  return (
    <ul>
      {items.map((item) => (
        <FilterItem key={item.label}>
          <Checkbox
            handleFilter={() => {
              refine(item.value);
            }}
            id={item.label}
            checked={item.isRefined}
          />
          <label>
            {hashTag && '#'}
            {item.label}
          </label>
        </FilterItem>
      ))}
      {items.length > 0 ? <ClearAllFilters /> : ''}
    </ul>
  );
};

const FilterItem = styled.li`
  display: flex;
  margin-bottom: 12px;
`;

export default connectRefinementList(RefinementList);
