import styled from '@emotion/styled';
import React, { useState } from 'react';
import colors from '../../../assets/styles/colors';
import ModalCard from '../../landing_components/ModalCard';
import SocialMediaLink from './SocialMediaLink';
import StoryCarousel from './StoryCarousel';
import StoryContent from './StoryContent';

const ContentCard = ({
  date_stamp,
  title,
  description,
  name,
  postalCode,
  storyMediaContent,
  link,
  socialMediaSource,
}) => {
  const [showModal, setShowModal] = useState(false);

  // Prevent background content scrolling when card modal open
  if (showModal) {
    document.body.style.overflow = 'hidden';
  } else if (!showModal) {
    document.body.style.overflow = 'unset';
  }

  const handleShowExpandedCard = () => {
    setShowModal(true);
  };

  return (
    <div>
      {showModal && (
        <ModalCard
          setShowModal={setShowModal}
          showModal={showModal}
          storyMediaContent={storyMediaContent}
          isStoryPage={true}
          date_stamp={date_stamp}
          title={title}
          description={description}
          name={name}
          postalCode={postalCode}
          handleShowExpandedCard={() => handleShowExpandedCard()}
        />
      )}
      <CardWrapper color={colors}>
        {socialMediaSource && (
          <SocialMediaLink link={link} socialMediaSource={socialMediaSource} />
        )}
        <StoryCarousel
          storyMediaContent={storyMediaContent}
          handleShowExpandedCard={() => handleShowExpandedCard()}
        />
        <StoryContent
          date_stamp={date_stamp}
          title={title}
          description={description}
          name={name}
          postalCode={postalCode}
          handleShowExpandedCard={() => handleShowExpandedCard()}
        />
      </CardWrapper>
    </div>
  );
};

const CardWrapper = styled.div`
  background-color: ${(props) => props.color.white};
  width: 360px;
  height: auto;
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05),
    0px 30px 60px 5px rgba(49, 49, 49, 0.15);
`;

export default ContentCard;
