import styled from '@emotion/styled';
import xIcon from 'assets/images/x-icon.svg';
import colors from 'assets/styles/colors';
import React, { useEffect, useRef, useState } from 'react';
import ProgressBar from './ProgressBar';
import QuitProgressModal from './QuitProgressModal';
import StepOne from './StepOne/StepOne';
import StepThree from './StepThree';
import StepTwo from './StepTwo/StepTwo';
import breakPoints from 'assets/styles/breakpoints';

const UploadModal = (props) => {
  const { openModal, onChange } = props;
  const [open, setOpen] = useState(false);
  const modalRef = useRef();
  const [stepNumber, setStepNumber] = useState(1);
  const [openQuitModal, setOpenQuitModal] = useState(false);
  const [isImageCropView, setIsImageCropView] = useState(false);
  const [storySubmission, setStorySubmission] = useState(null);

  // Prevent background content scrolling when modal open
  if (open) {
    document.body.style.overflow = 'hidden';
  } else if (!open) {
    document.body.style.overflow = 'unset';
  }

  // states form step1
  //new for upload preview suggested solution
  const [previewArray, setPreviewArray] = useState([]);

  const [form, setForm] = useState({
    title: null,
    name: null,
    date: null,
    postalCode: '',
    storyCategory: '',
    story: null,
  });

  /* functions for opening/closing the modal */
  useEffect(() => {
    setOpen(openModal);
  }, [openModal]);

  //for opening/closing quitProgressModal
  const handleQuitProgressModal = (show) => {
    setIsImageCropView(false);
    setOpenQuitModal(!show);
  };

  //closes the entire modal, clears all files
  const quitModal = () => {
    onChange(open);
    setStepNumber(1);
    clearFiles();
    setPreviewArray([]);
    setStorySubmission(null);
  };

  //called when modal is closed to set parent's openModal states back
  const closeModal = () => {
    //check if on step 1 or 2 to show quitProgressModal
    if (stepNumber === 1 || stepNumber === 2) {
      setOpenQuitModal(true);
      //if on step 3 close modal
    } else {
      quitModal();
    }
  };

  const clearFiles = () => {
    setForm({
      title: '',
      name: '',
      date: '',
      postalCode: '',
      storyCategory: '',
      story: '',
    });
  };

  const backToStepOne = () => {
    setStepNumber(1);
    clearFiles();
  };

  //detects clicks outside modal contents (if click is outside modal contents then close the modal)
  useEffect(() => {
    const handleClick = (e) => {
      if (modalRef.current && !modalRef.current.contains(e.target)) {
        closeModal();
      }
    };
    document.addEventListener('mousedown', handleClick);
    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  });

  //this function is passed to each step to handle when stepNumber is changed
  const handleChangeStep = (stepNumber) => {
    setStepNumber(stepNumber);
  };

  //renders the correct step component based on stepNumber
  const renderStepView = () => {
    if (stepNumber == 1) {
      return (
        <StepOne
          changeStep={handleChangeStep}
          form={form}
          close={closeModal}
          setForm={setForm}
          setStorySubmission={setStorySubmission}
          storySubmission={storySubmission}
        />
      );
    } else if (stepNumber == 2) {
      return (
        <StepTwo
          changeStep={handleChangeStep}
          storySubmission={storySubmission}
          setStorySubmission={setStorySubmission}
          previewArray={previewArray}
          setPreviewArray={setPreviewArray}
          isImageCropView={isImageCropView}
          setIsImageCropView={setIsImageCropView}
        />
      );
    } else {
      return (
        <StepThree
          changeStep={handleChangeStep}
          backToStepOne={backToStepOne}
          closeModal={closeModal}
        />
      );
    }
  };

  return (
    open && (
      <div>
        <Overlay>
          <ModalContent ref={modalRef} className="frame">
            {isImageCropView ? '' : <ProgressBar number={stepNumber} />}

            {renderStepView()}
            <ExitButton onClick={closeModal}>
              <img src={xIcon} alt="x icon" />
            </ExitButton>
          </ModalContent>
        </Overlay>
        <QuitProgressModal
          openModal={openQuitModal}
          handleChange={handleQuitProgressModal}
          closeParentModal={quitModal}
        />
      </div>
    )
  );
};

const Overlay = styled.div`
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .frame {
    overflow-y: auto;
  }

  .frame::-webkit-scrollbar {
    -webkit-appearance: none;
  }

  .frame::-webkit-scrollbar:vertical {
    width: 10px;
  }

  .frame::-webkit-scrollbar-thumb {
    border-radius: 8px;
    border: 1px solid white;
    background-color: ${colors.darkGrey};
  }
`;

const ModalContent = styled.div`
  width: 640px;
  background-color: white;
  border-radius: 10px;
  max-height: 85%;
  z-index: 99;

  position: relative;
  color: black;
  .error {
    text-align: center;
    color: red;
  }

  @media only screen and (max-width: ${breakPoints.tablet}) {
    height: 100vh;
    max-height: 100%;
    width: 100vw;
    border-radius: 0px;
  }
`;

const ExitButton = styled.button`
  border: none;
  background: none;
  position: absolute;
  cursor: pointer;
  top: 0;
  right: 0;
  padding: 20px;
`;

export default UploadModal;
